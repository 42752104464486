import React, {useEffect, useState} from "react";


export interface CustomToastData {
    message: string
    show: boolean
}

export interface CustomToastNotificationProps {
    toast: CustomToastData
    toggleToast?: boolean
}

export function CustomToastNotification(props: CustomToastNotificationProps) {
    let [toast, setToast] = useState<CustomToastData>({
        message: props.toast.message,
        show: props.toast.show
    })

    let toastStyle = "flex items-center w-full max-w-xs p-4 text-indigo-500 bg-indigo-100 rounded-lg shadow-lg"
    let svgStyle = "inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-indigo-100 bg-indigo-500 rounded-lg"

    let onClick = () => {
        setToast({
            ...toast,
            show: false
        })
    }

    useEffect(() => {
        setToast(props.toast)
    }, [props.toast])


    return (
        <div className={"fixed top-4 right-4 z-50"}>
            {
                toast.show &&
                <div
                    id={"toast-default"}
                    className={toastStyle}
                    role={"alert"}
                >
                    <div className={svgStyle}>
                        <i className="fa-solid fa-spinner animate-spin" />
                    </div>

                    <div className={"ml-3 text-sm font-normal"}>
                        {props.toast.message}
                    </div>

                    {
                        props.toggleToast &&
                        <button
                            onClick={onClick}
                            className={
                                "ml-auto mx-1.5 my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg " +
                                "focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 flex items-center h-8 w-8 "
                            }
                            data-collapse-toggle={"toast-default"}
                            aria-label={"Close"}
                        >
                            <span className={"sr-only"}>Close</span>
                            <i className="fa-solid fa-xmark" />
                        </button>
                    }
                </div>
            }
        </div>
    )
}