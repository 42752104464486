import {NotificationListResponse, NotificationUpdateResponse} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {endpoints} from "../api";


export class NotificationApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | NotificationListResponse> {
        let url = endpoints.notification.list;
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static update(
        accessToken: string,
        objectId: string,
        markAsRead: boolean,
    ): Promise<FormErrorResponse | NotificationUpdateResponse> {
        let url = endpoints.notification.update.replace(':objectId', objectId);

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    mark_as_read: markAsRead
                })
            }
        );
    }
}