import {Quote} from "../../../../models";
import {useEffect, useState} from "react";
import {BlueButton} from "@boomrank/react-components"
import {defaultLanguage, getTranslation} from "../../../../intl";

interface Props {
    quote: Quote
    handleSubmit: (quote: Quote) => void
    disabled?: boolean
}

export function QuoteForm(props: Props) {
    let [quote, setQuote] = useState<Quote>(props.quote)

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: any = event.target.value
        if (event.target.name === 'refDate') {
            value = new Date(event.target.value)
        }
        setQuote({
            ...quote,
            [event.target.name]: value
        })
    }

    useEffect(() => {
        setQuote(props.quote)
    }, [props.quote])

    return (
        <div>
            <div className={'p-3'}>
                <label className={'w-16'}>
                    {
                        getTranslation('NETLINKING.FORM.QUOTE.LABEL_REF')
                    }
                </label>
                <input type={'text'}
                       name={'ref'}
                       onChange={onChange}
                       className={'ml-3 p-3 border rounded'}
                       value={quote.ref}
                       disabled={props.disabled || false}
                />
            </div>
            <div className={'p-3'}>
                <label className={'w-16'}>
                    {
                        getTranslation('NETLINKING.FORM.QUOTE.LABEL_REF_DATE')
                    }
                </label>
                <input type={"date"}
                       name={'refDate'}
                       onChange={onChange}
                       pattern={'\d{4}-\d{2}-\d{2}'}
                       className={'ml-3 p-3 border rounded'}
                       value={
                           quote.refDate.toLocaleDateString('en-CA')
                       }
                       data-testid={'refDate'}
                       disabled={props.disabled || false}
                />
                <span className={'pl-6'}>
                    {
                        quote.refDate.toLocaleDateString(
                            defaultLanguage, {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            }
                        )
                    }
                </span>
            </div>
            <BlueButton
                text={
                    getTranslation('NETLINKING.FORM.QUOTE.LABEL_SUBMIT')
                }
                onClick={() => {
                    props.handleSubmit(quote)
                }}
                disabled={props.disabled || false}
            />
        </div>
    )
}