import React, {useEffect, useState} from "react";
import {Cache, JwtToken, ToastData, ToastNotification} from "@boomrank/react-components";

import {
    Account,
    Customer,
    Identifier,
    Link as Backlink,
    Provider,
    Quote
} from "../../../../models";

import {LinkApi} from "../../../../services/link/api";
import {LinkResponse} from "../../../../services/link/responses";
import {LinkFactory} from "../../../../services/link/factory";
import {defaultLanguage, getTranslation} from "../../../../intl";

import {LinkForm} from "../../forms/link";
import {LinkSummary} from "../../../dummies/link/summary";
import {AnalyzeLinkResultAnchorType} from "../../../dummies/link/analyze/anchor-type";
import {AnalyzeLinkResultIsValid} from "../../../dummies/link/analyze/is-valid";

import {CheckAnalysisLinks} from "./check-analysis";
import {LinkBulkOperations} from "./bulk";


interface Props {
    token: JwtToken
    link: Backlink
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheQuotes: Cache<Quote>
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function LinkEditView(props: Props) {
    let [account, setAccount] = useState<Account>()
    let [customer, setCustomer] = useState<Customer>()
    let [provider, setProvider] = useState<Provider>()
    let [quote, setQuote] = useState<Quote>()

    let [toast, setToast] = useState<ToastData>({
        success: false,
        message: '',
        show: false,
    })

    let onError = (message: string) => {
        setToast({
            success: false,
            message: message,
            show: true,
        })
    }

    let onSubmit = (link: Backlink) => {
        LinkApi.update(props.token, link).then(r => {
            if(r.statusCode >= 200 && r.statusCode < 300) {
                let response = r as LinkResponse
                props.cacheUpdater(LinkFactory.fromFragment(response.data))

                setToast({
                    success: true,
                    message: getTranslation('NETLINKING.VIEW.LINK.EDIT.SUCCESS'),
                    show: true,
                })
            } else {
                setToast({
                    success: true,
                    message: getTranslation('NETLINKING.VIEW.LINK.EDIT.ERROR'),
                    show: true,
                })
            }
        })
    }

    useEffect(() => {
        let accounts = props.cacheAccounts.get(props.link.accountId)
        if (accounts.length > 0) {
            setAccount(accounts[0])
        }
        let customers = props.cacheCustomers.get(props.link.customerId)
        if (customers.length > 0) {
            setCustomer(customers[0])
        }
        let providers = props.cacheProviders.get(props.link.providerId)
        if (providers.length > 0) {
            setProvider(providers[0])
        }
        if (props.link.quoteId) {
            let quotes = props.cacheQuotes.get(props.link.quoteId)
            if (quotes.length > 0) {
                setQuote(quotes[0])
            }
        }
    }, [props.link])


    return (
        <div className={'w-auto'}>
            <div className={'w-full bg-white p-3 mt-3 rounded-lg shadow-sm'}>
                <div className={'w-full flex flex-col gap-y-3 sm:flex-row sm:justify-end sm:items-end gap-x-3'}>
                    <LinkBulkOperations
                        token={props.token}
                        links={[props.link]}
                        cacheUpdater={props.cacheUpdater}
                    />

                    <CheckAnalysisLinks
                        token={props.token}
                        cacheUpdater={props.cacheUpdater}
                        disabled={props.link.broken}
                    />
                </div>

                <LinkSummary
                    link={props.link}
                    account={account}
                    customer={customer}
                    quote={quote}
                    provider={provider}
                />
            </div>

            <div className={'bg-white p-3 mt-3 rounded-lg shadow-sm'}>
                <ToastNotification toast={toast} />

                <LinkForm
                    link={props.link}
                    onSubmit={onSubmit}
                    onError={onError}
                    disabled={props.link.broken || props.link.isValid === true}
                />
            </div>

            {
                props.link.lastUpdate &&
                <div className={'bg-white p-3 mt-3 rounded-lg shadow-sm'}>
                    <h3 className={'w-full mb-8 p-3'}>
                        {getTranslation('NETLINKING.VIEW.LINK.EDIT.TITLE_LAST_ANALYZE')}
                    </h3>

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_UPDATE')}
                        isValid={props.link.isValid}
                        broken={props.link.broken}
                        result={props.link.lastUpdate.toLocaleString(defaultLanguage)}
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_TARGET_URL')}
                        isValid={props.link.urlValid}
                        broken={props.link.broken}
                        result={
                            props.link.requestedUrl ?
                                <span>{props.link.requestedUrl}</span> :
                                <i>{getTranslation('NETLINKING.VIEW.LINK.EDIT.URL_NOT_FOUND')}</i>
                        }
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_IS_INDEXED')}
                        isValid={props.link.isIndexed}
                        broken={props.link.broken}
                        result={
                            props.link.isIndexed === true ?
                                getTranslation('NETLINKING.VIEW.LINK.EDIT.IS_INDEXED')
                                :
                                props.link.isIndexed === null ?
                                    <>
                                        <i className={'fa-solid fa-lock mr-3'} />
                                        {getTranslation('NETLINKING.VIEW.LINK.EDIT.IS_DISABLED')}
                                    </>
                                    :
                                    getTranslation('NETLINKING.VIEW.LINK.EDIT.IS_NOT_INDEXED')
                        }
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_HTTP_CODE')}
                        isValid={props.link.httpCodeValid}
                        broken={props.link.broken}
                        result={props.link.lastHttpCode}
                    />

                    <AnalyzeLinkResultAnchorType link={props.link} />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_CONTENT')}
                        isValid={props.link.contentValid}
                        broken={props.link.broken}
                        result={
                            props.link.requestedContent ?
                                props.link.requestedContent :
                                <i>{getTranslation('NETLINKING.VIEW.LINK.EDIT.LAST_CONTENT_EMPTY')}</i>
                        }
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_REL')}
                        isValid={props.link.relValid}
                        broken={props.link.broken}
                        result={
                            props.link.lastRel ?
                                props.link.lastRel :
                                <i>{getTranslation('NETLINKING.VIEW.LINK.EDIT.LAST_REL_EMPTY')}</i>
                        }
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_HEADERS')}
                        isValid={props.link.headersValid}
                        broken={props.link.broken}
                        result={
                            <p className={'whitespace-pre-line'}>
                                {props.link.lastHeaders}
                            </p>
                        }
                    />

                    <AnalyzeLinkResultIsValid
                        title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_META')}
                        isValid={props.link.metaValid}
                        broken={props.link.broken}
                        result={
                            <p className={'whitespace-pre-line'}>
                                {props.link.lastMeta}
                            </p>
                        }
                    />
                </div>
            }
        </div>
    )
}
