import {QuoteListResponse, QuoteResponse} from "./responses";
import {endpoints} from "../api";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {Quote} from "../../models";

export class QuoteApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | QuoteListResponse>  {
        let url = endpoints.quote.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static update(
        token: JwtToken,
        quote: Quote
    ): Promise<FormErrorResponse | QuoteResponse>  {
        let url = endpoints.quote.update.replace(':pk', quote.id.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    customer_id: quote.customerId,
                    ref_date: quote.refDate.toLocaleDateString('en-CA'),
                    ref: quote.ref,
                })
            }
        );
    }


    static delete(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | QuoteResponse> {
        let url = endpoints.quote.delete.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}