import React from "react";
import classNames from "classnames";


export interface Option {
    id: number
    content: string
    title: string
    selected?: boolean
}

interface Props {
    options: Option[]
    onChange: (id: number) => void
    name: string
    value: number
    disabled?: boolean
    legend?: string | React.ReactNode | React.ReactNode[]
    classname?: string
}

export function SelectInput(props: Props) {
    let handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange(parseInt(event.target.value))
    }

    return (
        <fieldset className={classNames(
            'w-full border border-gray-100',
            props.classname
        )}>
            {
                props.legend &&
                <legend>{props.legend}</legend>
            }

            <select
                disabled={props.disabled}
                name={props.name}
                value={props.value}
                onChange={handleChange}
                className={'w-full outline-0 px-3 py-3 lg:py-1.5 bg-white cursor-pointer rounded-sm'}
            >
                {
                    props.options.map((option, idx) => {
                        return (
                            <option
                                key={idx}
                                value={option.id}
                                title={option.title}
                            >
                                {option.content}
                            </option>
                        )
                    })
                }
            </select>
        </fieldset>
    )
}
