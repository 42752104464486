import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {Customer, Identifier, Link as Backlink, Quote} from "../../../../models";
import {CustomerApi} from "../../../../services/customer/api";

import {FormErrorResponse, JwtToken, RedButton} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    customer: Customer
    links: Backlink[]
    quotes: Quote[]
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    navigateTo?: string
}

export function CustomerDeleteView(props: Props) {
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false);


    let onClick = (customer: Customer) => {
        setLoading(true)

        let confirm = window.confirm(
            getTranslation('NETLINKING.VIEW.CUSTOMER.DELETE.CONFIRM_JS')
        )

        if (confirm) {
            CustomerApi.delete(
                props.token,
                customer.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    props.links.forEach(link => props.cacheUpdater(link, true))
                    props.quotes.forEach(quote => props.cacheUpdater(quote, true))

                    props.cacheUpdater(customer, true)

                    if (props.navigateTo) {
                        return navigate(props.navigateTo)
                    }
                } else {
                    res = res as FormErrorResponse;
                    console.error(res.data)
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }


    return (
        <div className={'w-full mt-4 p-8 bg-red-100 rounded-lg shadow-sm flex justify-end'}>
            <RedButton
                data-testid={'customer-delete-btn'}
                text={`Supprimer définitivement ${props.customer.name}`}
                onClick={() => onClick(props.customer)}
                disabled={loading}
            />
        </div>
    )
}