import React, {useState} from "react";

import {JwtToken, Cache} from "@boomrank/react-components";
import {
    Link as Backlink,
    Customer,
    Provider,
    Quote,
    Account,
    Identifier
} from "../../../../models";

import {LinkTableView} from "./table";


interface Props {
    token: JwtToken,
    links: Backlink[]
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function LinkListView(props: Props) {
    let columns = [
        'key',
        'validState',
        'targetUrl',
        'url',
        'targetContent',
        'targetExpression',
        'providerName',
        'languageName',
        'quoteRef',
    ]

    let [links, setLinks] = useState<Backlink[]>(props.links)

    let onToggleValid = (state: 'all' | 'valid' | 'issues' | 'broken' | 'underCorrection') => {
        let selectedRows = props.links

        if (state === 'valid') {
            selectedRows = props.links.filter(link => link.isValid && !link.underCorrection)
        }
        if (state === 'issues') {
            selectedRows = props.links.filter(link => !link.broken && !link.isValid && !link.underCorrection)
        }
        if (state === 'broken') {
            selectedRows = props.links.filter(link => link.broken && !link.isValid && !link.underCorrection)
        }
        if (state === 'underCorrection') {
            selectedRows = props.links.filter(link => link.underCorrection)
        }

        setLinks(selectedRows)
    }

    return (
        <LinkTableView
            token={props.token}
            links={links}
            cacheCustomers={props.cacheCustomers}
            cacheProviders={props.cacheProviders}
            cacheQuotes={props.cacheQuotes}
            cacheAccounts={props.cacheAccounts}
            columns={columns}
            cacheUpdater={props.cacheUpdater}
            onToggleValid={onToggleValid}
        />
    )

}