import React from "react";
import {Route, Routes} from "react-router-dom";
import {JwtToken, Cache} from "@boomrank/react-components";

import {
    Account,
    AccountHasCustomer,
    Customer,
    Identifier,
    Link,
    Notification,
    Provider,
    Quote
} from "../../models";

import {NetlinkingHomeRoute} from "./routes";
import {NetlinkingCustomerListRoute} from "./routes/customer/list";
import {NetlinkingCustomerGetRoute} from "./routes/customer/get";
import {NetlinkingLinkListRoute} from "./routes/link/list";
import {NetlinkingLinkEditRoute} from "./routes/link/edit";
import {NetlinkingQuoteListRoute} from "./routes/quote/list";
import {NetlinkingQuoteGetRoute} from "./routes/quote/get";
import {NetlinkingQuoteEditRoute} from "./routes/quote/edit";


interface Props {
    token: JwtToken
    me: Account
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheLinks: Cache<Link>
    cacheQuotes: Cache<Quote>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function NetlinkingDashboard(props: Props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <NetlinkingHomeRoute
                        token={props.token}
                        me={props.me}
                        cacheLinks={props.cacheLinks}
                        cacheCustomers={props.cacheCustomers}
                        cacheQuotes={props.cacheQuotes}
                        cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                        cacheNotifications={props.cacheNotifications}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
            <Route
                path={'/customer/'}
                element={
                    <NetlinkingCustomerListRoute
                        token={props.token}
                        customers={props.cacheCustomers.values()}
                        accountHasCustomers={props.cacheAccountHasCustomers.values()}
                        cacheQuotes={props.cacheQuotes}
                        cacheLinks={props.cacheLinks}
                    />
                }
            />
            <Route
                path={'/customer/:customerId/'}
                element={
                    <NetlinkingCustomerGetRoute
                        token={props.token}
                        cacheAccounts={props.cacheAccounts}
                        cacheCustomers={props.cacheCustomers}
                        cacheProviders={props.cacheProviders}
                        cacheQuotes={props.cacheQuotes}
                        cacheLinks={props.cacheLinks}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />

            <Route path={'/link/'} element=
                {
                    <NetlinkingLinkListRoute
                        token={props.token}
                        links={props.cacheLinks.values()}
                        cacheAccounts={props.cacheAccounts}
                        cacheProviders={props.cacheProviders}
                        cacheCustomers={props.cacheCustomers}
                        cacheQuotes={props.cacheQuotes}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
            <Route path={'/link/:linkId/'} element=
                {
                    <NetlinkingLinkEditRoute
                        token={props.token}
                        cacheAccounts={props.cacheAccounts}
                        cacheCustomers={props.cacheCustomers}
                        cacheProviders={props.cacheProviders}
                        cacheQuotes={props.cacheQuotes}
                        cacheLinks={props.cacheLinks}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />

            <Route path={'/quote/'} element=
                {
                    <NetlinkingQuoteListRoute
                        token={props.token}
                        cacheLinks={props.cacheLinks}
                        cacheCustomers={props.cacheCustomers}
                        quotes={props.cacheQuotes.values()}
                        cacheProviders={props.cacheProviders}
                    />
                }
            />
            <Route path={'/quote/:quoteId/'} element=
                {
                    <NetlinkingQuoteGetRoute
                        token={props.token}
                        cacheAccounts={props.cacheAccounts}
                        cacheCustomers={props.cacheCustomers}
                        cacheProviders={props.cacheProviders}
                        cacheQuotes={props.cacheQuotes}
                        cacheLinks={props.cacheLinks}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
            <Route path={'/quote/:quoteId/edit/'} element=
                {
                    <NetlinkingQuoteEditRoute
                        token={props.token}
                        cacheQuotes={props.cacheQuotes}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
        </Routes>
    )
}