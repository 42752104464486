import {
    CustomerListResponse, CustomerResponse,
} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {endpoints} from "../api";
import {Customer, Account} from "../../models";

export class CustomerApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | CustomerListResponse> {
        let url = endpoints.customer.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static retrieve(
        token: JwtToken,
        id: string
    ): Promise<FormErrorResponse | CustomerResponse> {
        let url = endpoints.customer.retrieve.replace(':pk', id);
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static create(
        token: JwtToken,
        customer: Customer,
        accounts: Account[]
    ): Promise<FormErrorResponse | CustomerResponse> {
        let url = endpoints.customer.create;
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    name: customer.name,
                    accounts: accounts.map(a => a.id)
                })
            }
        );
    }

    static update(
        token: JwtToken,
        customer: Customer,
        accountIds: number[]
    ): Promise<FormErrorResponse | CustomerResponse> {
        let url = endpoints.customer.update.replace(':pk', customer.id.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    id: customer.id,
                    name: customer.name,
                    accounts: accountIds
                })
            }
        );
    }

    static delete(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | CustomerResponse> {
        let url = endpoints.customer.delete.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}
