import React from "react";
import {useLocation} from "react-router-dom";
import {
    Account,
    AccountHasCustomer,
    Customer,
    Identifier,
    Link as Backlink,
    Notification,
    Quote
} from "../../../models";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {NetlinkingHomeView} from "../views";
import {getTranslation} from "../../../intl";


interface Props {
    token: JwtToken
    me: Account
    cacheLinks: Cache<Backlink>
    cacheCustomers: Cache<Customer>
    cacheQuotes: Cache<Quote>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function NetlinkingHomeRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('NETLINKING.ROUTE.HOME.TITLE')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/netlinking/`, content:'Dashboard', title: getTranslation('BREADCRUMB.HOMEPAGE')},
                ]}
            />

            <NetlinkingHomeView
                token={props.token}
                me={props.me}
                cacheLinks={props.cacheLinks}
                cacheCustomers={props.cacheCustomers}
                cacheQuotes={props.cacheQuotes}
                cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}