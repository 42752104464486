import {LinkListResponse, LinkResponse} from "./responses";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {endpoints} from "../api";
import {Link} from "../../models";


export class LinkApi {
    static list(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static update(
        token: JwtToken,
        link: Link
    ): Promise<FormErrorResponse | LinkResponse> {
        let url = endpoints.link.update.replace(':pk', link.id.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    is_ok: link.isOk,
                    requested_url: link.requestedUrl,
                    target_url: link.requestedUrl,
                    url_valid: link.urlValid,
                    requested_content: link.requestedContent,
                    target_content: link.requestedContent,
                    content_valid: link.contentValid,
                    requested_regex: link.requestedRegex,
                    target_regex: link.targetRegex,
                    comment: link.comment,
                    language: link.language,
                    price_ht: link.priceHt,
                    provider: link.providerId,
                    quote: link.quoteId,
                })
            }
        );
    }

    static delete(
        token: JwtToken,
        pk: number,
    ): Promise<FormErrorResponse | LinkResponse> {
        let url = endpoints.link.delete.replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}