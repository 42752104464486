import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import {JwtToken, Cache, Column, ColumnType, FullTable, PaginateAdvancedTable} from "@boomrank/react-components";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";

import {Account, AccountHasCustomer, Identifier} from "../../../../models";
import {StandaloneCustomer} from "../../../../models/standalone";
import {ExportApi} from "../../../../services/export/api";
import {NotificationEditView} from "../../../notification/views/edit";
import {AccountHasCustomerEditView} from "./account-has-customer/edit";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomers: StandaloneCustomer[]
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    columns: (keyof StandaloneCustomer)[]
    localStorageColumns?: string
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function StandaloneCustomerTableView(props: Props) {
    let defaultColumns: Column<StandaloneCustomer>[] = [
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.NAME'),
            type: ColumnType.STRING,
            accessor: 'name',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <Link to={`/dashboard/netlinking/customer/${row.id}`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: 'createdAt',
            cell: (row: StandaloneCustomer, value: any) => {
                return value.toLocaleDateString(
                    defaultLanguage,
                    {month: 'long', year: 'numeric'}
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.ISSUES_LINK'),
            type: ColumnType.NUMBER,
            accessor: 'issuesLinks',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <div className={'font-medium text-center'}>
                        <span className={classNames(
                            'mr-1',
                            {'text-orange-400': row.issuesLinks !== 0},
                            {'text-br-green': row.issuesLinks === 0}
                        )}>
                        {row.issuesLinks}
                        </span>
                        <span>
                            / {row.nbLinks}
                        </span>
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.BROKEN_LINK'),
            type: ColumnType.NUMBER,
            accessor: 'brokenLinks',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <div className={'font-medium text-center'}>
                        <span className={classNames(
                            'mr-1',
                            {'text-red-500': row.brokenLinks !== 0},
                            {'text-br-green': row.brokenLinks === 0}
                        )}>
                        {row.brokenLinks}
                        </span>
                        <span>
                            / {row.nbLinks}
                        </span>
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.QUOTE'),
            type: ColumnType.NUMBER,
            accessor: 'nbQuotes',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <div className={'font-medium text-center'}>
                        {value}
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.TOTAL_BUDGET'),
            type: ColumnType.NUMBER,
            accessor: 'budget',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <div className={'text-center'}>
                        {
                            value.toLocaleString(
                                defaultLanguage,
                                {style: 'currency', currency: 'EUR'}
                            )
                        }
                    </div>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.ACTION'),
            type: ColumnType.STRING,
            accessor: 'accountId',
            cell: (row: StandaloneCustomer, value: any) => {
                let customerAccount = props.cacheAccountHasCustomers.values().find((ahc) => (
                    ahc.customerId === row.id &&
                    ahc.accountId === props.me.id
                ))

                return (
                    <div className={'w-fit flex gap-x-1.5'}>
                        <AccountHasCustomerEditView
                            me={props.me}
                            token={props.token}
                            cacheUpdater={props.cacheUpdater}
                            standaloneCustomer={row}
                            accountHasCustomer={customerAccount}
                            accountHasCustomers={props.cacheAccountHasCustomers.values()}
                        />

                        <NotificationEditView
                            token={props.token}
                            customerId={row.id}
                            accountId={props.me.id}
                            customerHasAccount={customerAccount ? customerAccount : null}
                            cacheUpdater={props.cacheUpdater}
                        />
                    </div>
                )
            }
        },
    ]

    let onClickExport = (customers: StandaloneCustomer[]) => {
        if (customers.length > 0) {
            ExportApi.csv(props.token, customers)
        }
    }

    return (
        <FullTable
            rows={props.standaloneCustomers}
            formatter={() => props.standaloneCustomers}
            columns={defaultColumns}
            showColumns={props.columns}
            localStorageColumns={props.localStorageColumns}
            onClickExport={onClickExport}
            labelExport={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.EXPORT')}
            infoMessage={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.INFO_MESSAGE')}
            labelOptionAll={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.ALL')}
        >
            <PaginateAdvancedTable
                translator={translator}
                mobileColumnsDisplayed={[0, 2, 4]}
            />
        </FullTable>
    )
}