import React from "react";


export interface Props {
    title: string
    icon: React.ReactNode | React.ReactNode[] | null
    result: string | string [] | React.ReactNode | React.ReactNode[]
}

export function AnalyzeLinkResult(props: Props) {
    return (
        <div className={'w-full p-3 grid grid-cols-8 border-b border-slate-100 hover:bg-slate-50 last:border-0'}>
            <div className={'font-bold col-span-4 sm:col-span-2 md:col-span-1 transition-all'}>
                {props.title}
            </div>

            <div className={'text-center col-span-4 sm:col-span-1 md:w-fit transition-all'}>
                {props.icon}
            </div>

            <div className={'max-sm:hidden sm:col-span-5 md:col-span-6 transition-all'}>
                {props.result}
            </div>
        </div>
    )
}