import React, {useEffect, useState} from "react";

import {Link} from "../../../../models";
import {getTranslation} from "../../../../intl";
import {ResponsiveTextarea} from "@boomrank/react-components";


interface Props {
    link: Link
    onSubmit: (link: Link) => void,
    onError: (message: string) => void,
    disabled?: boolean
}

export function LinkForm(props: Props) {
    let [link, setLink] = useState<Link>(props.link)
    let [haveChanges, setHaveChanges] = useState(false)
    let [indexLastUrl, setIndexLastUrl] = useState(0)

    let onSubmit = () => {
        let isValid = true

        if (link.requestedUrl.trim().length === 0) {
            isValid = false
            props.onError(
                getTranslation('NETLINKING.FORM.LINK.REQUESTED_URL_EMPTY')
            )
        }

        let url;
        try {
            url = new URL(link.requestedUrl.trim())
        } catch (_) {
            isValid = false
            props.onError(
                getTranslation('NETLINKING.FORM.LINK.REQUESTED_URL_INVALID')
            )
        }

        if (isValid) {
            link.isOk = (link.urlValid && link.contentValid) || link.isOk
            props.onSubmit(link)
            setHaveChanges(false)
        }
    }

    let onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setIndexLastUrl(parseInt(event.target.value))
        setHaveChanges(true)
    }

    let onClickReplace = (targetName: keyof Link, fromName: keyof Link) => {
        let l = {
            ...link,
            [targetName]: link[fromName]
        }

        if (fromName === 'lastContent') {
            l = {
                ...link,
                [targetName]: link[fromName],
                contentValid: true
            }
        }

        setHaveChanges(true)
        setLink(l)
    }

    let onClickLastUrl = () => {
        if (props.link.lastUrls) {
            setHaveChanges(true)

            setLink({
                ...link,
                requestedUrl: props.link.lastUrls[indexLastUrl],
                urlValid: true
            })
        }
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value : string | null = event.target.value.trim()
        setHaveChanges(true)

        if (event.target.name === 'requestedRegex') {
            if(value.length === 0) {
                value = null
            }
        }
        let l = {
            ...link,
            [event.target.name]: value
        }
        setLink(l)
    }

    let onChangeTextarea = (name: string, value: string | number) => {
        setHaveChanges(true)

        setLink({
            ...link,
            [name]: value
        })
    }

    useEffect(() => {
        setLink(props.link)
    }, [props.link])


    return (
        <>
            <div className={'w-full mb-8 py-3 flex justify-between'}>
                <h3>
                    {getTranslation('NETLINKING.VIEW.LINK.EDIT.TITLE_SETTINGS')}
                </h3>

                {
                    !link.isValid &&
                    <button
                        data-testid={'netlinking-form-link-submit'}
                        className={'btn-green p-2 rounded-md disabled:hover:bg-gray-100'}
                        onClick={onSubmit}
                        disabled={props.disabled || !haveChanges}
                    >
                        {getTranslation('NETLINKING.FORM.LINK.LABEL_SUBMIT')}
                    </button>
                }
            </div>

            <div className={'grid grid-cols-1 md:grid-cols-3'}>
                <div className={'col-span-2 border-0 border-b border-slate-100 p-3 py-6'}>
                    <div className={'pb-1 font-bold'}>
                        { getTranslation('NETLINKING.FORM.LINK.LABEL_URL') }
                    </div>
                    <input
                        className={'p-3 border rounded w-full'}
                        placeholder={link.requestedUrl || getTranslation('NETLINKING.FORM.LINK.LABEL_URL')}
                        onChange={onChange}
                        type={'url'}
                        name={'requestedUrl'}
                        value={link.requestedUrl}
                        disabled={props.disabled || false}
                        data-testid={'netlinking-form-link-requestedUrl'}
                    />
                </div>

                <div className={'grid grid-cols-1 md:grid-cols-2 gap-3 content-center p-3 py-6 border-0 border-b border-slate-100'}>
                    {
                        !link.isValid &&
                        <>
                            <div>
                                {
                                    link.requestedUrl.replace(/\/$/, '') !== props.link.targetUrl.replace(/\/$/, '') &&
                                    <>
                                        <button
                                            data-testid={'netlinking-form-link-requestedUrl-reset'}
                                            className={'btn-blue block mx-auto p-2 rounded-md disabled:hover:bg-gray-100'}
                                            onClick={() => onClickReplace("requestedUrl", "targetUrl")}
                                            disabled={props.disabled || false}
                                        >
                                            {getTranslation('NETLINKING.FORM.LINK.URL_RESET')}
                                        </button>

                                        <div className={'text-center text-sm mt-4'}>
                                            <div>
                                                {
                                                    props.link.targetUrl ?
                                                        <b>{props.link.targetUrl}</b> :
                                                        <i>{getTranslation('NETLINKING.FORM.LINK.EMPTY')}</i>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div>
                                {
                                    (
                                        (props.link.lastUrls && props.link.lastUrls.length > 1) ||
                                        (
                                            props.link.lastUrls && props.link.lastUrls.length === 1 &&
                                            props.link.lastUrls[0].replace(/\/$/, '') !== link.requestedUrl.replace(/\/$/, '')
                                        )
                                    ) &&
                                    <>
                                        <button
                                            data-testid={'netlinking-form-link-requestedUrl-update'}
                                            className={'btn-green block mx-auto p-2 rounded-md disabled:hover:bg-gray-100'}
                                            onClick={onClickLastUrl}
                                            disabled={props.disabled || false}
                                        >
                                            {getTranslation('NETLINKING.FORM.LINK.URL_UPDATE')}
                                        </button>

                                        <div className={'text-center text-sm mt-4'}>
                                            <div>
                                                <select onChange={onSelect} className={'p-3 border mb-3'}>
                                                    {
                                                        props.link.lastUrls.map((u, idx) => {
                                                            return (
                                                                <option key={idx} value={idx} disabled={u === link.requestedUrl}>{u}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }

                </div>

                <div className={'col-span-2 p-3 py-6 border-0 border-b border-slate-100'}>
                    <div className={'pb-1 font-bold'}>
                        {getTranslation('NETLINKING.FORM.LINK.LABEL_ANCHOR')}
                    </div>

                    <input
                        className={'p-3 border rounded w-full'}
                        placeholder={link.requestedContent || getTranslation('NETLINKING.FORM.LINK.LABEL_ANCHOR')}
                        onChange={onChange}
                        type={'text'}
                        name={'requestedContent'}
                        value={link.requestedContent || ''}
                        disabled={props.disabled || false}
                        data-testid={'netlinking-form-link-requestedContent'}
                    />
                </div>

                <div className={'grid grid-cols-2 gap-3 content-center p-3 py-6 border-0 border-b border-slate-100'}>
                    {
                        !link.isValid &&
                        <>
                            <div>
                                {
                                    link.requestedContent !== props.link.targetContent &&
                                    <>
                                        <button
                                            data-testid={'netlinking-form-link-requestedContent-reset'}
                                            className={'btn-blue block mx-auto p-2 rounded-md disabled:hover:bg-gray-100'}
                                            onClick={() => onClickReplace("requestedContent", "targetContent")}
                                            disabled={props.disabled || false}
                                        >
                                            {getTranslation('NETLINKING.FORM.LINK.LABEL_RESET')}
                                        </button>

                                        <div className={'text-center text-sm mt-4'}>
                                            <div>
                                                {
                                                    props.link.targetContent ?
                                                        <b>{props.link.targetContent}</b> :
                                                        <i>{getTranslation('NETLINKING.FORM.LINK.EMPTY')}</i>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div>
                                {
                                    link.requestedContent !== props.link.lastContent &&
                                    <>
                                        <button
                                            data-testid={'netlinking-form-link-requestedContent-update'}
                                            className={'btn-green block mx-auto p-2 rounded-md disabled:hover:bg-gray-100'}
                                            onClick={() => onClickReplace("requestedContent", "lastContent")}
                                            disabled={props.disabled || false}
                                        >
                                            {getTranslation('NETLINKING.FORM.LINK.LABEL_UPDATE')}
                                        </button>

                                        <div className={'text-center text-sm mt-4'}>
                                            <div>
                                                {
                                                    props.link.lastContent ?
                                                        <b>{props.link.lastContent}</b> :
                                                        <i>{getTranslation('NETLINKING.FORM.LINK.EMPTY')}</i>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>

                <div className={'col-span-3 p-3 py-6 border-0 border-t-8 border-zinc-50'}>
                    <div className={'pb-1 font-bold'}>
                        {getTranslation('NETLINKING.FORM.LINK.LABEL_COMMENT')}
                    </div>

                    <ResponsiveTextarea
                        name={'comment'}
                        value={link.comment}
                        onChange={onChangeTextarea}
                        dataTestId={'netlinking-form-link-comment'}
                    />
                </div>

                {/*<div className={'col-span-2 p-3 py-6 border-0'}>*/}
                {/*    <div className={'pb-1 font-bold'}>*/}
                {/*        {getTranslation('NETLINKING.FORM.LINK.LABEL_REGEX')}*/}
                {/*    </div>*/}

                {/*    <input*/}
                {/*        className={'p-3 border rounded w-full'}*/}
                {/*        placeholder={link.requestedRegex || getTranslation('NETLINKING.FORM.LINK.LABEL_REGEX')}*/}
                {/*        onChange={onChange}*/}
                {/*        type={'text'}*/}
                {/*        name={'requestedRegex'}*/}
                {/*        value={link.requestedRegex || ''}*/}
                {/*        disabled={props.disabled || false}*/}
                {/*        data-testid={'netlinking-form-link-requestedRegex'}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className={'grid grid-cols-2 gap-3 content-center p-3 py-6 border-0'}>*/}
                {/*    {*/}
                {/*        !link.isValid &&*/}
                {/*        <>*/}
                {/*            {*/}
                {/*                link.requestedRegex !== props.link.targetRegex &&*/}
                {/*                <div className={'w-full flex flex-col items center'}>*/}
                {/*                    <button*/}
                {/*                        data-testid={'netlinking-form-link-requestedRegex-reset'}*/}
                {/*                        className={'btn-blue block mx-auto p-2 rounded-md disabled:hover:bg-gray-100'}*/}
                {/*                        onClick={() => onClickReplace("requestedRegex", "targetRegex")}*/}
                {/*                        disabled={props.disabled || false}*/}
                {/*                    >*/}
                {/*                        {getTranslation('NETLINKING.FORM.LINK.REGEX_UPDATE')}*/}
                {/*                    </button>*/}

                {/*                    <div className={'text-center text-sm mt-4'}>*/}
                {/*                        <div>*/}
                {/*                            {*/}
                {/*                                props.link.targetRegex ?*/}
                {/*                                    <b>{props.link.targetRegex}</b> :*/}
                {/*                                    <i>{getTranslation('NETLINKING.FORM.LINK.EMPTY')}</i>*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*        </>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </>
    )
}