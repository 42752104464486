import React from "react";
import classNames from "classnames";

import {FilterGroup, Group} from './index';
import {FilterOperator} from "@boomrank/react-components/src/global";


export interface GroupFilter<T> {
    field: keyof T
    operator: FilterOperator
    value: any
    test?: (item: T, search: any) => boolean
    active: boolean
    selected: boolean
}

export interface Props<T> {
    group: FilterGroup
    groups: Group<T>[]
    onSearch: (groups: Group<T>[]) => void
    isLoading?: boolean
}

export function GroupFilters<T>(props: Props<T>) {
    let isLoading = props.isLoading || false
    let selectedGroup = props.groups.find((g) => g.label === props.group.label)

    let search = (group: Group<T>) => {
        props.onSearch([
            ...props.groups.filter((g) => g.label !== group.label),
            group
        ])
    }

    let addFilterGroup = (filter: GroupFilter<T>) => {
        let filterGroup = {
            field: filter.field,
            filters: [
                {
                    field: filter.field,
                    value: filter.value,
                    operator: filter.operator,
                    test: filter.test,
                    active: filter.active,
                    selected: filter.selected
                }
            ],
            label: props.group.label
        }
        search(filterGroup)
    }

    let addFilter = (filter: GroupFilter<T>, group: Group<T>) => {
        let filterGroup = {
            field: group.field as keyof T,
            filters: [
                ...group.filters.filter((f) => f.value !== filter.value),
                filter
            ],
            label: group.label
        }
        search(filterGroup)
    }

    let removeFilter = (filter: GroupFilter<T>, group: Group<T>) => {
        let filterGroup = {
            field: group.field as keyof T,
            filters: group.filters.filter((f) => f.value !== filter.value),
            label: group.label
        }
        search(filterGroup)
    }

    let onClick = (filter: GroupFilter<T>) => {
        filter.selected = !filter.selected

        let groupExist = props.groups.find((f) => f.label === props.group.label)

        if (!groupExist) {
            addFilterGroup(filter)
        }

        if (groupExist) {
            if (groupExist.filters.map((f) => f).length === 0) {
                addFilter(filter, groupExist)
            }

            let filterExist = groupExist.filters.find((f) => f.value === filter.value)

            if (filterExist) {
                removeFilter(filter, groupExist)
            } else {
                addFilter(filter, groupExist)
            }
        }
    }


    return (
        <div className={'w-full h-auto flex flex-col md:flex-row items-center p-3 bg-slate-50 rounded-lg mb-3'}>
            <span className={'w-full font-medium mb-2 md:w-32 md:mb-0'}>
                {props.group.label}
            </span>

            <div className={'w-full max-h-48 overflow-y-auto flex flex-wrap justify-end md:justify-start gap-3'}>
                {
                    props.group.options.map((option, idx) => {
                        let filter = {
                            field: props.group.field as keyof T,
                            value: option.value,
                            operator: props.group.operator,
                            test: props.group.test,
                            active: option.active,
                            selected: option.selected,
                        }

                        if (selectedGroup) {
                            filter.selected = selectedGroup.filters.map((f) => f.value).includes(filter.value)
                        }

                        return (
                            <button
                                key={idx}
                                onClick={() => onClick(filter)}
                                className={classNames(
                                    'py-1 px-2 rounded lg:min-w-[4rem] transition-all',
                                    {'bg-br-green text-white hover:bg-slate-500 hover:text-white': filter.selected && filter.active},
                                    {'bg-br-green-300 text-white hover:bg-slate-500 hover:text-white': filter.selected && !filter.active},
                                    {'bg-br-blue-700 text-white hover:bg-br-green hover:text-white': !filter.selected && filter.active},
                                    {'bg-slate-300 text-slate-500 hover:bg-br-green hover:text-white': !filter.selected && !filter.active},
                                    {'cursor-event-none bg-slate-200 text-slate-500': isLoading},
                                )}
                                disabled={isLoading}
                            >
                                {filter.value}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}