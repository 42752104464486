import React from "react";
import classNames from "classnames";

import {JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../intl";

import {Identifier, AccountHasCustomer} from "../../../models";
import {CustomerHasAccountApi} from "../../../services/customer/has-account/api";
import {
    AccountHasCustomerCreateResponse,
    AccountHasCustomerUpdateResponse
} from "../../../services/account-has-customer/responses";
import {AccountHasCustomerFactory} from "../../../services/account-has-customer/factory";


interface Props {
    token: JwtToken
    customerId: number
    accountId: number
    customerHasAccount: AccountHasCustomer | null
    cacheUpdater: (obj: Identifier) => void
    className?: string
}

export function NotificationEditView(props: Props) {
    let customerHasAccount = props.customerHasAccount

    let editAccountHasCustomer = (accountHasCustomer: AccountHasCustomer) => {
        CustomerHasAccountApi.update(
            props.token,
            accountHasCustomer.customerId,
            accountHasCustomer.accountId,
            accountHasCustomer.notificationEnabled
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as AccountHasCustomerUpdateResponse
                props.cacheUpdater(AccountHasCustomerFactory.fromFragment(response.data))
            }
        })
    }

    let addAccountHasCustomer = (accountHasCustomer: AccountHasCustomer) => {
        CustomerHasAccountApi.create(
            props.token,
            accountHasCustomer.customerId,
            accountHasCustomer.notificationEnabled
        ).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                response = response as AccountHasCustomerCreateResponse
                props.cacheUpdater(AccountHasCustomerFactory.fromFragment(response.data))
            }
        })
    }

    let onSubmit = (accountHasCustomer: AccountHasCustomer | null) => {
        if (accountHasCustomer) {
            accountHasCustomer.notificationEnabled = !accountHasCustomer.notificationEnabled
            editAccountHasCustomer(accountHasCustomer)
        } else {
            accountHasCustomer = new AccountHasCustomer()
            accountHasCustomer.customerId = props.customerId
            accountHasCustomer.accountId = props.accountId
            accountHasCustomer.notificationEnabled = true
            addAccountHasCustomer(accountHasCustomer)
        }
    }


    if (customerHasAccount && customerHasAccount.notificationEnabled) {
        return (
            <div
                className={classNames(
                    'bg-teal-50 text-teal-500 w-12 py-2 rounded-md text-sm text-center hover:bg-teal-100 cursor-pointer transition-all',
                    props.className
                )}
                title={getTranslation('NOTIFICATION.VIEWS.EDIT.DISABLE')}
                onClick={() => onSubmit(customerHasAccount)}
            >
                <i className="fa-solid fa-bell" />
            </div>
        )
    }

    return (
        <div
            className={classNames(
                'bg-slate-50 text-slate-500 w-12 py-2 rounded-md text-sm text-center hover:bg-teal-100 hover:text-teal-500 cursor-pointer transition-all',
                props.className
            )}
            title={getTranslation('NOTIFICATION.VIEWS.EDIT.ENABLE')}
            onClick={() => onSubmit(customerHasAccount)}
        >
            <i className="fa-solid fa-bell-slash" />
        </div>
    )
}
