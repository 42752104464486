import React from "react";
import classNames from "classnames";

import {Account, AccountHasCustomer, Customer, Identifier} from "../../../../../models";
import {FormErrorResponse, JwtToken} from "@boomrank/react-components";

import {CustomerApi} from "../../../../../services/customer/api";
import {CustomerResponse} from "../../../../../services/customer/responses";
import {CustomerFactory} from "../../../../../services/customer/factory";
import {CustomerHasAccountApi} from "../../../../../services/customer/has-account/api";
import {StandaloneCustomer} from "../../../../../models/standalone";
import {getTranslation} from "../../../../../intl";
import {AccountHasCustomerFactory} from "../../../../../services/account-has-customer/factory";
import {AccountHasCustomerListResponse} from "../../../../../services/account-has-customer/responses";


interface Props {
    token: JwtToken
    me: Account
    standaloneCustomer: StandaloneCustomer
    accountHasCustomer: AccountHasCustomer | undefined
    accountHasCustomers: AccountHasCustomer[]
    cacheUpdater: (obj: Identifier, remove: boolean) => void
}

export function AccountHasCustomerEditView(props: Props) {
    let getTitle = () => {
        let title = getTranslation('NETLINKING.VIEW.ACCOUNT_HAS_CUSTOMER.UNFOLLOW')

        if (!props.accountHasCustomer) {
            title = getTranslation('NETLINKING.VIEW.ACCOUNT_HAS_CUSTOMER.FOLLOW')
        }
        return title
    }

    let getCustomerHasAccounts = (customer: Customer) => {
        CustomerHasAccountApi.list(
            props.token,
            customer.id
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                res.data.results.forEach((fragment) => {
                    props.cacheUpdater(AccountHasCustomerFactory.fromFragment(fragment), false)
                })
            }
        })
    }

    let onClick = (accountHasCustomer: AccountHasCustomer | undefined) => {
        let accountIds = props.standaloneCustomer.accountIds

        if (accountHasCustomer) {
            accountIds = props.standaloneCustomer.accountIds.filter((accountId) =>
                accountId !== accountHasCustomer.accountId
            )
        } else {
            accountIds.push(props.me.id)
        }

        CustomerApi.update(
            props.token,
            props.standaloneCustomer,
            accountIds,
        ).then(res => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as CustomerResponse;

                let deletedAccountHasCustomer = props.standaloneCustomer.accountHasCustomers
                    .filter(ahc => ahc.customerId === props.standaloneCustomer.id)
                deletedAccountHasCustomer.forEach(ahc => props.cacheUpdater(ahc, true))

                let updatedCustomer = CustomerFactory.fromFragment(res.data)
                updatedCustomer.accountIds = accountIds
                props.cacheUpdater(updatedCustomer, false)

                getCustomerHasAccounts(updatedCustomer)
            } else {
                res = res as FormErrorResponse;
                console.error(res)
            }
        })
    }


    return (
        <button
            className={classNames(
                'w-12 py-2 rounded-md text-sm text-center cursor-pointer transition-all',
                {'bg-slate-50 text-slate-500 hover:bg-teal-100 hover:text-teal-500': !props.accountHasCustomer},
                {'bg-teal-50 text-teal-500 hover:bg-teal-100': props.accountHasCustomer}
            )}
            title={getTitle()}
            onClick={() => onClick(props.accountHasCustomer)}
        >
            {
                props.accountHasCustomer ?
                    <i className={"fa-solid fa-star"} /> :
                    <i className={"fa-regular fa-star"} />
            }
        </button>
    )
}