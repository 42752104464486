import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation, useNavigate} from "react-router-dom";

import {Customer, Identifier, Link, Provider, Quote, Account} from "../../../../models";

import {Breadcrumb, JwtToken, Cache, Button} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {QuoteDeleteView} from "../../views/quote/delete";
import {LinkTableView} from "../../views/link/table";


interface Props {
    token: JwtToken
    cacheQuotes: Cache<Quote>
    cacheLinks: Cache<Link>
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier) => void
}

export function NetlinkingQuoteGetRoute(props: Props) {
    let [quote, setQuote] = useState<Quote>()
    let params = useParams()
    let location = useLocation()
    let navigate = useNavigate()

    useEffect(() => {
        if (params.quoteId) {
            let quotes = props.cacheQuotes.get(params.quoteId)
            if (quotes.length > 0) {
                setQuote(quotes[0])
                document.title = getTranslation('NETLINKING.ROUTE.QUOTE.GET.TITLE').format(
                    quotes[0].ref,
                )
            }
        }
    }, [params.quoteId, props.cacheQuotes])

    if (!quote) {
        return <>Error</>
    }

    let onEdit = () => {
        navigate(`/dashboard/netlinking/quote/${quote?.id}/edit/`)
    }

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/netlinking/`,
                        content: 'Dashboard',
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/netlinking/quote/`,
                        content: getTranslation('BREADCRUMB.QUOTES')
                    },
                    {
                        to: `/dashboard/netlinking/quote/${quote.id}/`,
                        content: <>{quote.ref}</>,
                        title: `${quote.ref}`
                    },
                ]}
            />

            <div className={'bg-white mt-4 p-8 rounded-lg shadow-sm sm:flex justify-end gap-x-3'}>
                <div className={'w-full lg:w-72 py-6 px-5 bg-zinc-100 rounded-xl'}>
                    <span className={'w-full text-gray-500 font-medium'}>
                        Modifier le devis :
                    </span>

                    <Button
                        data-testid={'quote-edit-btn'}
                        text={getTranslation('NETLINKING.VIEW.QUOTE.LIST.LABEL_EDIT')}
                        onClick={onEdit}
                        className={
                            'transition-all bg-teal-500 text-white block mt-3 mx-auto p-2 rounded-md ' +
                            'hover:bg-teal-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-gray-100'
                        }
                    />
                </div>

                <QuoteDeleteView
                    token={props.token}
                    quote={quote}
                    cacheUpdater={props.cacheUpdater}
                    navigateTo={`/dashboard/netlinking/quote/`}
                />
            </div>

            <LinkTableView
                token={props.token}
                links={
                    props.cacheLinks.values().filter((link) => {
                        if (link.quoteId) {
                            return link.quoteId === quote!.id
                        }
                        return false
                    })
                }
                cacheCustomers={props.cacheCustomers}
                cacheProviders={props.cacheProviders}
                cacheQuotes={props.cacheQuotes}
                cacheAccounts={props.cacheAccounts}
                columns={[
                    'key',
                    'createdAt',
                    'userEmail',
                    'url',
                    'providerName',
                    'priceHt',
                    'languageName',
                ]}
                previousPathContent={'quote'}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}