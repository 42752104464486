import {NotificationFragment} from "./fragments";
import {Notification} from "../../models";

export class NotificationFactory {
    static fromFragment = (fragment: NotificationFragment):  Notification => {
        let notification = new Notification()
        notification.id = fragment.id
        notification.createdAt = new Date(fragment.created_at)
        notification.username = fragment.username
        notification.customerId = fragment.args.customer_id
        notification.customerName = fragment.args.customer_name
        notification.code = fragment.code
        notification.args = [
            fragment.args.data.quote,
            fragment.args.data.link,
        ]
        notification.markAsRead = fragment.mark_as_read
        return notification
    }
}