import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

import {Notification} from "../../../models";

import {SecureHTML} from "@boomrank/react-components";
import {Option, SelectInput} from "../../dummies/select";
import {defaultLanguage, getTranslation} from "../../../intl";


interface Props {
    notifications: Notification[]
    notificationUpdater: (notification: Notification) => void
}


export function NotificationTableView(props: Props) {
    let [selectedCustomer, setSelectedCustomer] = useState<number>(0)
    let [notifications, setNotifications] = useState<Notification[]>(props.notifications)

    let selectedOptions = () => {
        let options = props.notifications.reduce(
            (customers: Option[], notification: Notification) => {
                let c = {
                    id: notification.customerId,
                    content: notification.customerName,
                    title: notification.customerName,
                }

                let uniques = customers.map((c: Option) => c.id)

                if (!uniques.includes(notification.customerId)) {
                    customers.push(c)
                }
                return customers
            }, []
        )

        options.unshift({
            id: 0,
            content: getTranslation('NOTIFICATION.VIEWS.TABLE.FILTER.DEFAULT'),
            title: getTranslation('NOTIFICATION.VIEWS.TABLE.FILTER.TITLE'),
        })

        return options
    }

    let onChange = (id: number) => {
        setSelectedCustomer(id)
    }

    let handleRead = (notification: Notification) => {
        props.notificationUpdater(notification)
    }

    useEffect(() => {
        if (selectedCustomer === 0) {
            setNotifications(props.notifications)
        } else {
            setNotifications(props.notifications.filter(n => n.customerId === selectedCustomer))
        }
    }, [props.notifications, selectedCustomer]);


    return (
        <div className={'mt-2 lg:mt-0'}>
            <SelectInput
                options={selectedOptions()}
                onChange={onChange}
                name={'customer'}
                value={selectedCustomer}
                classname={'w-full sm:w-72 py-1.5 border-2'}
            />

            <div className={'mt-12'}>
                {
                    notifications.map((notification: Notification, idx) => {
                        let localeDate = notification.createdAt.toLocaleDateString(defaultLanguage)
                        let createdAt = localeDate.charAt(0).toUpperCase() + localeDate.slice(1)

                        let code = notification.code.toUpperCase()

                        return (
                            <div
                                key={idx}
                                className={classNames(
                                    'p-2 border-b-2 flex flex-row sm:flex-row',
                                    {
                                        'bg-slate-50 border-b-white': notification.markAsRead,
                                        'bg-white border-b-slate-50': !notification.markAsRead
                                    }
                                )}
                            >
                                <div className={'w-full flex flex-col sm:flex-row'}>
                                    <p className={'w-full text-slate-500 italic text-sm mb-0 pb-0 sm:w-40'}>{createdAt}</p>
                                    <div className={'w-full flex flex-col mb-0'}>
                                        <Link
                                            className={'font-medium  w-fit'}
                                            to={`/dashboard/netlinking/customer/${notification.customerId}/`}
                                        >
                                            {notification.customerName}
                                        </Link>

                                        <div className={'text-slate-700 mb-0 pb-0'}>
                                            <SecureHTML>
                                                {getTranslation(`NOTIFICATION.VIEWS.TABLE.CODE.${code}`)}
                                            </SecureHTML>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={'flex item-start justify-center sm:items-center pl-3 sm:pl-6 md:pl-8'}>
                                    <button
                                        onClick={() => handleRead(notification)}
                                        className={classNames(
                                            'w-10 h-12 rounded-sm',
                                            {
                                                'btn-gray': notification.markAsRead,
                                                'btn-blue': !notification.markAsRead,
                                            }
                                        )}
                                    >
                                        {
                                            notification.markAsRead ?
                                                <i className="fa-solid fa-flag"></i> :
                                                <i className="fa-regular fa-flag"></i>
                                        }
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
