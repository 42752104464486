import React from "react";
import {JwtToken, Cache} from "@boomrank/react-components";
import {Customer, Link as Backlink, Provider, Quote} from "../../../../models";
import {QuoteTableView} from "./table";


interface Props {
    token: JwtToken
    cacheLinks: Cache<Backlink>
    cacheCustomers: Cache<Customer>
    quotes: Quote[]
    cacheProviders: Cache<Provider>
}

export function QuoteListView(props: Props) {
    return (
        <div className={'bg-white mt-4 p-8 rounded-lg shadow-sm'}>
            <QuoteTableView
                token={props.token}
                cacheLinks={props.cacheLinks}
                cacheCustomers={props.cacheCustomers}
                quotes={props.quotes}
                cacheProviders={props.cacheProviders}
                columns={[
                    'ref',
                    'customerName',
                    'refDate',
                    'nbLinks',
                    'cost',
                ]}
            />
        </div>
    )
}