import React, {useState} from "react";
import {BlueButton, JwtToken} from "@boomrank/react-components";

import {StandaloneLink} from "../../../models/standalone";
import {ExportApi} from "../../../services/export/api";

import {Option} from "../../dummies/filter";
import {defaultLanguage, getTranslation} from "../../../intl";
import {FinanceFormTable} from "./table";
import {FinanceFormFilters} from "./filters";
import {Card} from "../../dummies/card";


export interface Props {
    token: JwtToken
    standaloneLinks: StandaloneLink[]
}

export function FinanceForm(props: Props) {
    let months = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet',
        'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ]

    let [filteredRows, setFilteredRows] = useState<StandaloneLink[]>([])
    let [isLoading, setIsLoading] = useState<boolean>(true)

    let uniqueValues = (options: Option[], value: string | number) => {
        let values = options.map((option) => option.value)

        if (!values.includes(value)) {
            options.push({
                value: value,
                active: false,
                selected: false
            })
        }
        return options
    }

    // GroupFilter : return uniques values for each filter
    let uniqueYears = props.standaloneLinks.reduce(
        (years: Option[], standaloneLink: StandaloneLink) => {
            let year = standaloneLink.createdAt.getFullYear()
            return uniqueValues(years, year)
        }, []
    ).sort((a, b) => a.value - b.value)

    let uniqueMonths = props.standaloneLinks.reduce(
        (months: Option[], standaloneLink: StandaloneLink) => {
            let month = standaloneLink.createdAt.toLocaleDateString('fr-FR', {month: 'long'})
            return uniqueValues(months, month)
        }, []
    ).sort((a, b) => months.indexOf(a.value) - months.indexOf(b.value))

    let uniqueAccounts = props.standaloneLinks.reduce(
        (accounts: Option[], standaloneLink: StandaloneLink) => {
            let account = standaloneLink.accountUser
            return uniqueValues(accounts, account)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueCustomers = props.standaloneLinks.reduce(
        (customers: Option[], standaloneLink: StandaloneLink) => {
            let customer = standaloneLink.customerName
            return uniqueValues(customers, customer)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))

    let uniqueProviders = props.standaloneLinks.reduce(
        (providers: Option[], standaloneLink: StandaloneLink) => {
            let provider = standaloneLink.providerName
            return uniqueValues(providers, provider)
        }, []
    ).sort((a, b) => a.value.localeCompare(b.value))


    let getActiveFilters = () => {
        filteredRows.reduce(
            (rows, standaloneLink: StandaloneLink) => {
                let year = standaloneLink.createdAt.getFullYear()
                let month = standaloneLink.createdAt.toLocaleDateString('fr-FR', {month: 'long'})
                let account = standaloneLink.accountUser
                let customer = standaloneLink.customerName
                let provider = standaloneLink.providerName

                let years = uniqueYears.map((v) => v.value)
                let months = uniqueMonths.map((v) => v.value)
                let accounts = uniqueAccounts.map((v) => v.value)
                let customers = uniqueCustomers.map((v) => v.value)
                let providers = uniqueProviders.map((v) => v.value)

                if (years.includes(year)) {
                    let index = years.indexOf(year)
                    uniqueYears[index].active = true
                }
                if (months.includes(month)) {
                    let index = months.indexOf(month)
                    uniqueMonths[index].active = true
                }
                if (accounts.includes(account)) {
                    let index = accounts.indexOf(account)
                    uniqueAccounts[index].active = true
                }
                if (customers.includes(customer)) {
                    let index = customers.indexOf(customer)
                    uniqueCustomers[index].active = true
                }
                if (providers.includes(provider)) {
                    let index = providers.indexOf(provider)
                    uniqueProviders[index].active = true
                }

                return rows
            }, []
        )
    }
    getActiveFilters()

    let onClickExport = (links: StandaloneLink[]) => {
        if (links.length > 0) {
            let exportLinks = links.map((l) => {
                return {
                    id: l.id,
                    date: l.createdAt.toLocaleDateString('fr-FR'),
                    devis: l.quoteRef,
                    consultant: l.accountUser,
                    email: l.userEmail,
                    client: l.customerName,
                    plateforme: l.providerName,
                    prix: l.priceHt,
                    source: l.url,
                    lien: l.targetUrl,
                    valide: l.isValid
                }
            })

            ExportApi.csv(
                props.token,
                exportLinks,
                'backlink_quotes.csv'
            )
        }
    }

    let onFilter = (rows: any[]) => {
        setFilteredRows(rows)

        if (rows.length > 0) {
            setIsLoading(false)
        }
    }

    return (
        <div className={'relative bg-white mt-4 p-8 rounded-lg shadow-sm 2xl:flex 2xl:gap-x-16'}>
            <div className={'w-full mb-20 2xl:w-2/5'}>
                <BlueButton
                    text={getTranslation('FINANCE.FORM.EXPORT.BUTTON')}
                    onClick={() => onClickExport(filteredRows)}
                    disabled={isLoading}
                />

                <div className={'w-full 2xl:sticky 2xl:top-10'}>
                    <div className={'w-full my-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'}>
                        <Card
                            content={
                                <>{
                                    filteredRows.reduce((total, link) => total + (link.priceHt || 0), 0).toLocaleString(
                                        defaultLanguage,
                                        { style: 'currency', currency: 'EUR' }
                                    )
                                }</>
                            }
                            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_PRICE.LABEL')}
                            iconName={'fa-solid fa-euro-sign'}
                            iconColor={'text-amber-500 bg-amber-50'}
                        />
                        <Card
                            content={
                                <>{
                                    filteredRows.reduce(
                                        (quotes: string[], standaloneLink: StandaloneLink) => {
                                            let q = standaloneLink.quoteRef!
                                            if (!quotes.includes(q)) {
                                                quotes.push(q)
                                            }
                                            return quotes
                                        }, []
                                    ).length
                                }</>
                            }
                            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_QUOTE.LABEL')}
                            iconName={'fa-regular fa-file-lines'}
                            iconColor={'text-orange-500 bg-orange-50'}
                        />
                        <Card
                            content={
                                <>{ filteredRows.length }</>
                            }
                            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_LINK.LABEL')}
                            iconName={'fa-solid fa-link'}
                            iconColor={'text-indigo-500 bg-indigo-50'}
                        />
                    </div>

                    <FinanceFormFilters
                        standaloneLinks={props.standaloneLinks}
                        onFilter={onFilter}
                        filterGroups={{
                            years: uniqueYears,
                            months: uniqueMonths,
                            accounts: uniqueAccounts,
                            customers: uniqueCustomers,
                            providers: uniqueProviders
                        }}
                        isLoading={isLoading}
                    />
                </div>
            </div>

            <FinanceFormTable
                standaloneLinks={filteredRows}
                isLoading={isLoading}
            />
        </div>
    )
}