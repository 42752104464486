import React from "react";
import {AnalyzeLinkResult} from "./index";


export interface Props {
    title: string
    isValid: boolean | null
    broken: boolean
    result: string | string [] | React.ReactNode | React.ReactNode[]
}

export function AnalyzeLinkResultIsValid(props: Props) {
    let iconName = 'fa-circle-question text-gray-500'

    if (props.broken && !props.isValid) {
        iconName = 'fa-circle-xmark text-red-500'
    }

    if (!props.broken && !props.isValid) {
        iconName = 'fa-triangle-exclamation text-orange-400'
    }

    if (props.isValid) {
        iconName = 'fa-circle-check text-green-500'
    }


    return (
        <AnalyzeLinkResult
            title={props.title}
            icon={<i className={`fa-solid ${iconName}`}/>}
            result={props.result}
        />
    )
}