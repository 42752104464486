import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

import {Identifier, Quote} from "../../../../models";

import {Breadcrumb, JwtToken, Cache} from "@boomrank/react-components";
import {QuoteEditView} from "../../views/quote/edit";
import {getTranslation} from "../../../../intl";
import {QuoteDeleteView} from "../../views/quote/delete";


interface Props {
    token: JwtToken
    cacheQuotes: Cache<Quote>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function NetlinkingQuoteEditRoute(props: Props) {
    let [quote, setQuote] = useState<Quote>();
    let params = useParams();
    let location = useLocation()

    useEffect(() => {
        if (params.quoteId) {
            let quotes = props.cacheQuotes.get(params.quoteId)
            if (quotes.length > 0) {
                setQuote(quotes[0])
                document.title = `Devis ${quotes[0].ref} | Backlink Checker`
            }
        }
    }, [params.quoteId, props.cacheQuotes])

    if (!quote) {
        return <>Error</>
    }


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/netlinking/`,
                        content: 'Dashboard',
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/netlinking/quote/`,
                        content: getTranslation('BREADCRUMB.QUOTES')
                    },
                    {
                        to: `/dashboard/netlinking/quote/${quote.id}/`,
                        content: <>{quote.ref}</>,
                        title: `${quote.ref}`
                    },
                    {
                        to: `/dashboard/netlinking/quote/${quote.id}/edit/`,
                        content: getTranslation('BREADCRUMB.QUOTE_EDIT')
                    }
                ]}
            />

            <div className={'bg-white mt-4 p-8 rounded-lg shadow-sm sm:flex justify-end gap-x-3'}>
                <QuoteDeleteView
                    token={props.token}
                    quote={quote}
                    cacheUpdater={props.cacheUpdater}
                    navigateTo={`/dashboard/netlinking/quote/`}
                />
            </div>

            <div className={'bg-white mt-4 p-8 rounded-lg shadow-sm'}>
                <QuoteEditView
                    token={props.token}
                    quote={quote}
                    cacheUpdater={props.cacheUpdater}
                />
            </div>
        </>
    )
}