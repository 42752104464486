import React from "react";

import {LastType, Link as Backlink} from "../../../../models";

import {AnalyzeLinkResult} from "./index";
import {getTranslation} from "../../../../intl";


export interface Props {
    link: Backlink
}

export function AnalyzeLinkResultAnchorType(props: Props) {
    let iconClassName = 'fa-question'
    let resultValue = getTranslation(`LAST_TYPE.UNKNOWN.LABEL`)

    if (props.link.lastType === LastType.TXT) {
        iconClassName = 'fa-font'
        resultValue = getTranslation(`LAST_TYPE.${props.link.lastType}.LABEL`)
    }
    if (props.link.lastType === LastType.IMG) {
        iconClassName = 'fa-image'
        resultValue = getTranslation(`LAST_TYPE.${props.link.lastType}.LABEL`)
    }


    return (
        <AnalyzeLinkResult
            title={getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_TYPE')}
            icon={<i className={`fa-solid ${iconClassName}`} title={resultValue}/>}
            result={resultValue}
        />
    )
}