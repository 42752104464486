import React from "react";
import {useLocation} from "react-router-dom";

import {AccountHasCustomer, Customer, Link, Quote} from "../../../../models";

import {Breadcrumb, JwtToken, Cache} from "@boomrank/react-components";
import {CustomerListView} from "../../views/customer/list";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    customers: Customer[]
    accountHasCustomers: AccountHasCustomer[]
    cacheLinks: Cache<Link>
    cacheQuotes: Cache<Quote>
}

export function NetlinkingCustomerListRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('NETLINKING.ROUTE.CUSTOMER.LIST.TITLE')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/netlinking/`, content: 'Dashboard', title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: `/dashboard/netlinking/customer/`, content: getTranslation('BREADCRUMB.CUSTOMERS')},
                ]}
            />

            <CustomerListView
                token={props.token}
                customers={props.customers}
                accountHasCustomers={props.accountHasCustomers}
                cacheLinks={props.cacheLinks}
                cacheQuotes={props.cacheQuotes}
            />
        </>
    )
}