import React from "react";
import {useLocation} from "react-router-dom";

import {Customer, Link, Provider, Quote, Account, Identifier} from "../../../../models";

import {Breadcrumb, JwtToken, Cache} from "@boomrank/react-components";
import {LinkListView} from "../../views/link/list";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    links: Link[]
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function NetlinkingLinkListRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('NETLINKING.ROUTE.LINK.LIST.TITLE')

    return (
        <>
            <div className={'container-wrapper'}>
                <Breadcrumb
                    location={location}
                    links={[
                        {
                            to: `/dashboard/netlinking/`,
                            content: 'Dashboard',
                            title: getTranslation('BREADCRUMB.HOMEPAGE')
                        },
                        {
                            to: `/dashboard/netlinking/link/`,
                            content: getTranslation('BREADCRUMB.LINKS')
                        },
                    ]}
                />

                <LinkListView
                    token={props.token}
                    links={props.links}
                    cacheCustomers={props.cacheCustomers}
                    cacheProviders={props.cacheProviders}
                    cacheQuotes={props.cacheQuotes}
                    cacheAccounts={props.cacheAccounts}
                    cacheUpdater={props.cacheUpdater}
                />
            </div>
        </>
    )
}