import {endpoints} from "../api";
import {JwtToken} from "@boomrank/react-components";

export class ExportApi {
    static csv(
        token: JwtToken,
        obj: any[],
        fileName: string = 'backlink.csv'
    ): Promise<any> | undefined {
        let name = fileName
        let url = endpoints.export.csv

        if (obj.length > 0) {
            let columns = Object.keys(obj[0])
            let data = obj.map((o) => {
                return Object.values(o)
            })

            return fetch(
                url, {
                    method: 'POST',
                    body: JSON.stringify({
                        'data': data,
                        'columns': columns
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.access}`,
                    }
                }
            )
            .then((response) => response.blob())
            .then((blob) => {
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = name;
                a.click();
            }).catch((err) => {
                console.error(err);
            })
        }
    }
}