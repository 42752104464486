import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import {Link as Backlink, Identifier,} from "../../../../models";
import {LinkApi} from "../../../../services/link/api";

import {Button, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    link: Backlink
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    navigateTo?: string
}

export function LinkDeleteView(props: Props) {
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false);


    let onClick = (link: Backlink) => {
        setLoading(true)

        let confirm = window.confirm(
            getTranslation('NETLINKING.VIEW.LINK.DELETE.CONFIRM_JS')
        )

        if (confirm) {
            LinkApi.delete(
                props.token,
                link.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    props.cacheUpdater(link, true)

                    if (props.navigateTo) {
                        return navigate(props.navigateTo)
                    }
                } else {
                    res = res as FormErrorResponse;
                    console.error(res.data)
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }
    }


    return (
        <div className={'w-full mt-4 p-8 bg-red-100 rounded-lg shadow-sm flex flex-col gap-6 sm:flex-row justify-between items-center'}>
            <span className={'text-red-500'}>
                Suppression définitive du lien, à effectuer uniquement si et seulement si erreur dans l’importation du lien
            </span>

            <Button
                data-testid={'link-delete-btn'}
                text={'Supprimer définitivement'}
                onClick={() => onClick(props.link)}
                className={
                    'h-fit w-fit transition-all bg-red-500 text-white block p-2 rounded-md ' +
                    'hover:bg-red-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-gray-100'
                }
                disabled={loading}
            />
        </div>
    )
}