import React from "react";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";

interface Props {
    links: StandaloneLink[]
    validLinks: StandaloneLink[]
}

export function TotalValid(props: Props) {
    return (
        <Card
            iconName={'fa-solid fa-link-slash'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_VALID.LABEL')}
            content={<>{props.links.length - props.validLinks.length}</>}
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}