import React from "react";
import {Link} from "react-router-dom";
import {
    AdvancedDashboardTemplate,
    CustomIconLink,
    FeedbackTypeformPopover,
} from '@boomrank/react-components';
import {v4} from 'uuid';
import {Account} from '../../../models'

export function Template(props: {
    me: Account
    children?: React.ReactElement | React.ReactElement[]
}) {
    let menuItems = [
        <CustomIconLink
            key={v4()}
            title={'Clients'}
            to={'/dashboard/netlinking/'}
            className={'no-underline py-3 mt-10 mb-2 flex justify-center items-center rounded-md text-gray-600 hover:bg-sky-50 hover:text-sky-400'}
        >
            <i className={'fa-solid fa-star text-lg'} />
        </CustomIconLink>,
        <CustomIconLink
            key={v4()}
            title={'Liens'}
            to={'/dashboard/netlinking/link/'}
            className={'no-underline py-3 mb-2 flex justify-center items-center rounded-md text-gray-600 hover:bg-indigo-50 hover:text-indigo-400'}
        >
            <i className={'fa-solid fa-link text-lg'} />
        </CustomIconLink>,
        <CustomIconLink
            key={v4()}
            title={'Devis / Factures'}
            to={'/dashboard/netlinking/quote/'}
            className={'no-underline py-3 mb-2 flex justify-center items-center rounded-md text-gray-600 hover:bg-orange-50 hover:text-orange-400'}
        >
            <i className={'fa-solid fa-receipt text-lg'} />
        </CustomIconLink>,
        <CustomIconLink
            key={v4()}
            title={'Finances'}
            to={'/dashboard/finance/'}
            className={'no-underline py-3 flex justify-center items-center rounded-md text-gray-600 hover:bg-amber-50 hover:text-amber-400'}
        >
            <i className={'fa-solid fa-euro text-lg'} />
        </CustomIconLink>,
    ]

    if (props.me.user.email.indexOf('@digimood.com') !== -1) {
        menuItems.push(
            <CustomIconLink
                key={v4()}
                title={'Besoin d\'aide ?'}
                to={'https://faq.digimood.io/docs/documentation-seo/boomrank-9905/'}
                className={'no-underline mt-20 mb-10 py-3 flex justify-center items-center rounded-md text-gray-600 hover:bg-teal-50 hover:text-teal-400'}
            >
                <i className={'fa-regular fa-circle-question text-lg'} />
            </CustomIconLink>,
        )
    }


    return (
        <AdvancedDashboardTemplate
            logo={
                <Link to={'/dashboard/'}>
                    <img
                        alt={'BC logo'}
                        src={process.env.PUBLIC_URL + '/images/logo/favicon.png'}
                        className={'w-16'}
                    />
                </Link>
            }
            items={menuItems}
        >

            <div>{ props.children }</div>

            <div className={'mt-6 pb-20 text-xs text-br-blue-300 text-right'}>
                build #{ process.env.REACT_APP_RELEASE_BUILD } - { process.env.REACT_APP_RELEASE_DATE }
                <FeedbackTypeformPopover
                    id={'b6tP5J7P'}
                    hidden={{
                        'email': props.me.user.email,
                        'firstname': props.me.user.firstname,
                        'url': window.location.href,
                        'application': `backlink-checker`,
                        'build': `${process.env.REACT_APP_RELEASE_BRANCH}-${process.env.REACT_APP_RELEASE_BUILD}`,
                    }}
                    autoClose={3000}
                />
            </div>
        </AdvancedDashboardTemplate>
    )
}
