import React from "react";
import {JwtToken, Cache, ColumnType, Column, FullTable, PaginateAdvancedTable} from "@boomrank/react-components";
import {Link} from "react-router-dom";
import {Customer, Link as Backlink, Provider, Quote} from "../../../../models";
import {defaultLanguage, getTranslation, translator} from "../../../../intl";
import {StandaloneQuote} from "../../../../models/standalone";
import {StandaloneQuoteFactory} from "../../../../services/quote/factory";
import {ExportApi} from "../../../../services/export/api";


interface Props {
    token: JwtToken
    cacheLinks: Cache<Backlink>
    cacheCustomers: Cache<Customer>
    quotes: Quote[]
    cacheProviders: Cache<Provider>
    columns: (keyof StandaloneQuote)[]
}

export function QuoteTableView(props: Props) {
    let defaultColumns: Column<StandaloneQuote>[] = [
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.REF'),
            type: ColumnType.STRING,
            accessor:  'ref',
            cell: (row: StandaloneQuote, value: any) => {
                return (
                    <Link to={`/dashboard/netlinking/quote/${row.id}/`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.CUSTOMER_NAME'),
            type: ColumnType.STRING,
            accessor: 'customerName',
            cell: (row: StandaloneQuote) => {
                return (
                    <Link to={`/dashboard/netlinking/customer/${row.customerId}/`}>
                        {row.customerName}
                    </Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.REF_DATE'),
            type: ColumnType.DATE,
            accessor: 'refDate',
            cell: (row: StandaloneQuote) => {
                return row.refDate.toLocaleDateString(
                    defaultLanguage,
                    {day: '2-digit', month: '2-digit', year: 'numeric'}
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.NB_LINKS'),
            type: ColumnType.NUMBER,
            accessor: 'nbLinks',
        },
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.BROKEN_LINKS'),
            type: ColumnType.NUMBER,
            accessor: 'brokenLinks',
        },
        {
            header: getTranslation('NETLINKING.VIEW.QUOTE.TABLE.COST'),
            type: ColumnType.NUMBER,
            accessor: 'cost',
            cell: (row: StandaloneQuote) => {
                return row.cost.toLocaleString(
                    defaultLanguage,
                    {
                        style: 'currency',
                        currency: 'EUR'
                    }
                )
            }
        },
    ]

    let onClickExport = (quotes: StandaloneQuote[]) => {
        if (quotes.length > 0) {
            ExportApi.csv(props.token, quotes)
        }
    }

    let formatter = (quotes: Quote[]): StandaloneQuote[] => {
        return quotes.map((quote) => {
            return StandaloneQuoteFactory.fromQuote(
                quote,
                props.cacheLinks,
                props.cacheCustomers
            )
        });
    }

    return (
        <FullTable
            rows={props.quotes}
            formatter={formatter}
            columns={defaultColumns}
            showColumns={props.columns}
            localStorageColumns={'bc-quote-table'}
            onClickExport={onClickExport}
            labelExport={getTranslation('NETLINKING.VIEW.QUOTE.TABLE.EXPORT')}
            infoMessage={getTranslation('NETLINKING.VIEW.QUOTE.TABLE.INFO_MESSAGE')}
            labelOptionAll={getTranslation('NETLINKING.VIEW.QUOTE.TABLE.ALL')}
        >
            <PaginateAdvancedTable
                translator={translator}
            />
        </FullTable>
    )
}