import {QuoteForm} from "../../forms/quote";
import {Identifier, Quote} from "../../../../models";
import {QuoteApi} from "../../../../services/quote/api";
import {JwtToken} from "@boomrank/react-components";
import React, {useState} from "react";
import {QuoteFactory} from "../../../../services/quote/factory";
import {QuoteResponse} from "../../../../services/quote/responses";
import {getTranslation} from "../../../../intl";

interface Props {
    token: JwtToken
    quote: Quote
    cacheUpdater: (obj: Identifier) => void
}

export function QuoteEditView(props: Props) {
    let [isLoading, setIsLoading] = useState(false);
    let [error, setError] = useState('')
    let [success, setSuccess] = useState('')

    let handleSubmit = (quote: Quote) => {
        setIsLoading(true)
        setSuccess('')
        setError('')

        QuoteApi.update(props.token, quote).then((r) => {
            if (r.statusCode === 200) {
                let response = r as QuoteResponse
                let q = QuoteFactory.fromFragment(response.data)
                props.cacheUpdater(q)
                setSuccess(
                    getTranslation('NETLINKING.VIEW.QUOTE.EDIT.SUCCESS')
                )
            }
            else {
                setError(
                    getTranslation('NETLINKING.VIEW.QUOTE.EDIT.ERROR')
                )
            }
            setIsLoading(false)
        })
    }

    return (
        <>
            {
                error &&
                <div role={'alert'} className={'p-6 text-br-red border border-br-red bg-red-50 my-3'}>
                    {error}
                </div>
            }
            {
                success &&
                <div role={'alert'} className={'p-6 text-br-green border border-br-green bg-green-50 my-3'}>
                    {success}
                </div>
            }
            <QuoteForm
                quote={props.quote}
                handleSubmit={handleSubmit}
                disabled={isLoading}
            />
        </>
    )
}