import React from "react";
import {Account} from "../../../../models";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";

interface Props {
    accounts: Account[]
}

export function TotalUser(props: Props) {
    return (
        <Card
            iconName={'fa-solid fa-user-secret'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_USER.LABEL')}
            content={<>{props.accounts.length}</>}
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}