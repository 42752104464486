import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

import {Account, Customer, Identifier, Link, Provider, Quote} from "../../../../models";

import {Breadcrumb, JwtToken, Cache, PageNotFoundView} from "@boomrank/react-components";
import {LinkEditView} from "../../views/link/edit";
import {LinkDeleteView} from "../../views/link/delete";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheLinks: Cache<Link>
    cacheQuotes: Cache<Quote>
    cacheProviders: Cache<Provider>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void

}

export function NetlinkingLinkEditRoute(props: Props) {
    let params = useParams()
    let location = useLocation()

    let [link, setLink] = useState<Link>()

    let getPreviousPath = () => {
        if (location.state) {
            return location.state.previousPath
        }
        return '/dashboard/netlinking/link/'
    }

    useEffect(() => {
        if (params.linkId) {
            let links = props.cacheLinks.get(params.linkId)
            if (links.length) {
                setLink(links[0])
                document.title = getTranslation('NETLINKING.ROUTE.LINK.EDIT.TITLE').format(
                    links[0].url,
                )
            }
        }
    }, [params.linkId, props.cacheLinks])

    if (!link) {
        return <PageNotFoundView />
    }


    return (
        <>
            {
                location.state &&
                location.state.previousPathContent &&
                location.state.previousPath.includes('customer') &&
                    <Breadcrumb
                        location={location}
                        links={[
                            {
                                to: `/dashboard/netlinking/`,
                                content: 'Dashboard',
                                title: getTranslation('BREADCRUMB.HOMEPAGE')
                            },
                            {
                                to: `/dashboard/netlinking/customer/`,
                                content: getTranslation('BREADCRUMB.CUSTOMERS')
                            },
                            {
                                to: location.state.previousPath,
                                content: <>{location.state.previousPathContent}</>
                            },
                            {
                                to: `/dashboard/netlinking/link/${link.id}/`,
                                content: <>{link.url}</>,
                                disabled: true,
                            },
                        ]}
                    />
            }
            {
                location.state &&
                location.state.previousPathContent &&
                location.state.previousPath.includes('quote') &&
                    <Breadcrumb
                        location={location}
                        links={[
                            {
                                to: `/dashboard/netlinking/`,
                                content: 'Dashboard',
                                title: getTranslation('BREADCRUMB.HOMEPAGE')
                            },
                            {
                                to: `/dashboard/netlinking/quote/`,
                                content: getTranslation('BREADCRUMB.QUOTES')
                            },
                            {
                                to: location.state.previousPath,
                                content: <>{location.state.previousPathContent}</>
                            },
                            {
                                to: `/dashboard/netlinking/link/${link.id}/`,
                                content: <>{link.url}</>,
                                disabled: true,
                            },
                        ]}
                    />
            }
            {
                !location.state &&
                <Breadcrumb
                    location={location}
                    links={[
                        {to: `/dashboard/netlinking/`, content: 'Dashboard', title: getTranslation('BREADCRUMB.HOMEPAGE')},
                        {to: `/dashboard/netlinking/link/`, content: getTranslation('BREADCRUMB.LINKS')},
                        {
                            to: `/dashboard/netlinking/link/${link.id}/`,
                            content: <>{link.url}</>,
                            disabled: true,
                        },
                    ]}
                />
            }

            <LinkEditView
                token={props.token}
                link={link}
                cacheAccounts={props.cacheAccounts}
                cacheCustomers={props.cacheCustomers}
                cacheQuotes={props.cacheQuotes}
                cacheProviders={props.cacheProviders}
                cacheUpdater={props.cacheUpdater}
            />

            <LinkDeleteView
                token={props.token}
                link={link}
                cacheUpdater={props.cacheUpdater}
                navigateTo={getPreviousPath()}
            />
        </>
    )
}