import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";

import {Customer, Link, Provider, Quote, Account, Identifier} from "../../../../models";

import {Breadcrumb, JwtToken, Cache} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";
import {CustomerDeleteView} from "../../views/customer/delete";
import {LinkTableView} from "../../views/link/table";


interface Props {
    token: JwtToken
    cacheCustomers: Cache<Customer>
    cacheLinks: Cache<Link>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
    cacheAccounts: Cache<Account>
    cacheUpdater: (obj: Identifier, remove?: boolean) => void
}

export function NetlinkingCustomerGetRoute(props: Props) {
    let params = useParams()
    let location = useLocation()

    let [customer, setCustomer] = useState<Customer>()
    let [links, setLinks] = useState<Link[]>([])

    useEffect(() => {
        if (params.customerId) {
            let customers = props.cacheCustomers.get(params.customerId)
            if (customers.length) {
                setCustomer(customers[0])
                document.title = getTranslation('NETLINKING.ROUTE.CUSTOMER.GET.TITLE').format(
                    customers[0].name,
                )

                setLinks(
                    props.cacheLinks.values().filter((link) =>
                        link.customerId === customers[0].id
                    )
                )
            }
        }
    }, [params.customerId, props.cacheCustomers])

    if (!customer) {
        return <>Error</>
    }

    let onToggleValid = (state: 'all' | 'valid' | 'issues' | 'broken' | 'underCorrection') => {
        let customerLinks = props.cacheLinks.values().filter((link) =>
            link.customerId === customer!.id
        )

        let selectedRows = customerLinks
        if (state === 'valid') {
            selectedRows = customerLinks.filter(link => (link.isOk || link.isValid) && !link.underCorrection)
        }
        if (state === 'issues') {
            selectedRows = customerLinks.filter(link => !link.broken && !link.isValid && !link.underCorrection)
        }
        if (state === 'broken') {
            selectedRows = customerLinks.filter(link => link.broken && !link.isValid && !link.underCorrection)
        }
        if (state === 'underCorrection') {
            selectedRows = customerLinks.filter(link => link.underCorrection)
        }

        setLinks(selectedRows)
    }


    return (
        <div className={'w-full container-wrapper'}>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/netlinking/`, content: 'Dashboard', title: getTranslation('BREADCRUMB.HOMEPAGE')},
                    {to: `/dashboard/netlinking/customer/`, content: getTranslation('BREADCRUMB.CUSTOMERS')},
                    {to: `/dashboard/netlinking/customer/${customer.id}/`, content: <>{customer.name}</>, title: customer.name},
                ]}
            />

            <LinkTableView
                token={props.token}
                links={links}
                cacheCustomers={props.cacheCustomers}
                cacheProviders={props.cacheProviders}
                cacheQuotes={props.cacheQuotes}
                cacheAccounts={props.cacheAccounts}
                columns={[
                    'key',
                    'validState',
                    'targetUrl',
                    'url',
                    'targetContent',
                    'targetExpression',
                    'providerName',
                    'languageName',
                    'quoteRef'
                ]}
                previousPathContent={'customer'}
                cacheUpdater={props.cacheUpdater}
                onToggleValid={onToggleValid}
            />

            <CustomerDeleteView
                token={props.token}
                customer={customer}
                links={
                    props.cacheLinks.values().filter((link) =>
                        link.customerId === customer!.id
                    )
                }
                quotes={
                    props.cacheQuotes.values().filter((quote) =>
                        quote.customerId === customer!.id
                    )
                }
                cacheUpdater={props.cacheUpdater}
                navigateTo={'/dashboard/netlinking/'}
            />
        </div>
    )
}