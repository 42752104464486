import {AccountListResponse} from "./responses";
import {endpoints} from "../api";
import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";

export class AccountApi {
    static list(token: JwtToken, baseUrl?: string): Promise<FormErrorResponse | AccountListResponse> {
        let url = endpoints.account.list
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }
}