import React, {useState} from "react";
import {Button, JwtToken, ToastData, ToastNotification} from "@boomrank/react-components";

import {Identifier} from "../../../../../models";

import {LinkListResponse} from "../../../../../services/link/responses";
import {LinkFactory} from "../../../../../services/link/factory";
import {LinkBulkApi} from "../../../../../services/link/bulk/api";


export interface Props {
    token: JwtToken
    cacheUpdater: (obj: Identifier) => void
    disabled?: boolean
    onUpdateLinks?: () => void
}

export function CheckAnalysisLinks(props: Props) {
    let toastInit = {success: false, message: '', show: false}

    let [loading, setLoading] = useState<boolean>(false)
    let [toast, setToast] = useState<ToastData>(toastInit)

    let onClick = () => {
        setLoading(true)
        setToast(toastInit)

        LinkBulkApi.checkAnalysis(props.token).then(response => {
            if (response.statusCode === 200 && response.data.results.length > 0) {
                response = response as LinkListResponse

                response.data.results.forEach((l) => {
                    let backlink = LinkFactory.fromFragment(l)
                    props.cacheUpdater(backlink)
                })

                if (props.onUpdateLinks) {
                    props.onUpdateLinks()
                }

                setToast({
                    success: true,
                    message: `${response.data.results.length} liens ont été analysés au cours des 5 dernières minutes`,
                    show: true
                })
                setLoading(false)
            } else {
                let intervalId = setInterval(() => {
                    setToast({
                        success: false,
                        message: `Aucun lien envoyé ou les liens n'ont pas encore été crawlés...`,
                        show: true
                    })

                    setLoading(false)
                }, 3000)

                setTimeout(() => {
                    clearInterval(intervalId)
                }, 3000)
            }
        })
    }


    return (
        <div className={'w-fit'}>
            <ToastNotification toast={toast} />

            {
                loading &&
                <div className={
                    'h-fit w-fit transition-all py-3 px-6 rounded-md text-md font-medium' +
                    ' bg-teal-500 text-white block rounded-md ' +
                    'hover:bg-teal-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-gray-100'
                }>
                    <i className="fa-solid fa-spinner animate-spin mr-5" />
                    <span>Vérification en cours ...</span>
                </div>
            }

            {
                !loading &&
                <Button
                    data-testid={'check-analysis-links'}
                    text={"Vérifier le status du crawl"}
                    onClick={() => onClick()}
                    className={
                        'h-fit w-fit transition-all bg-teal-500 text-white block p-2 rounded-md ' +
                        'hover:bg-teal-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-gray-100'
                    }
                    disabled={props.disabled || false}
                />
            }
        </div>
    )
}