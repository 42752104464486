import React from "react";
import {defaultLanguage, getTranslation} from "../../../../intl";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";


interface Props {
    links: StandaloneLink[]
}

export function TotalPrice(props: Props) {
    let total = props.links.reduce((total, link) => total + (link.priceHt || 0), 0)

    return (
        <Card
            iconName={'fa-solid fa-euro-sign'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_PRICE.LABEL')}
            content={
                <>
                    {
                        total.toLocaleString(
                            defaultLanguage,
                            { style: 'currency', currency: 'EUR' }
                        )
                    }
                </>
            }
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}