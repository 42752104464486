import React from "react";
import {Route, Routes} from "react-router-dom";
import {JwtToken, Cache} from "@boomrank/react-components";

import {
    Account,
    Customer, Identifier,
    Link,
    Notification,
    Provider,
    Quote
} from "../../models";

import {FinanceHomeRoute} from "./routes";


interface Props {
    token: JwtToken
    cacheLinks: Cache<Link>
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function FinanceDashboard(props: Props) {
    return (
        <Routes>
            <Route
                index
                element={
                    <FinanceHomeRoute
                        token={props.token}
                        links={props.cacheLinks.values()}
                        cacheAccounts={props.cacheAccounts}
                        cacheCustomers={props.cacheCustomers}
                        cacheProviders={props.cacheProviders}
                        cacheQuotes={props.cacheQuotes}
                        cacheNotifications={props.cacheNotifications}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            />
        </Routes>
    )
}