import {AccountFragment} from "./fragments";
import {UserFactory} from "../user/factory";
import {Account} from "../../models";

export class AccountFactory {
    static fromFragment = (fragment: AccountFragment): Account =>  {
        let user = UserFactory.fromFragment(fragment.user);

        let account = new Account()
        account.id = fragment.id
        account.user = user
        return account
    }
}