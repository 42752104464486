import React from "react";
import {Link} from "react-router-dom";
import {defaultLanguage, getTranslation, translator} from "../../../intl";

import {StandaloneLink} from "../../../models/standalone";
import {Column, ColumnType, PaginateAdvancedTable, TableV2} from "@boomrank/react-components";


export interface Props {
    standaloneLinks: StandaloneLink[]
    isLoading?: boolean
}

export function FinanceFormTable(props: Props) {
    let isLoading = props.isLoading || false

    let defaultColumns: Column<StandaloneLink>[] = [
        {
            header: getTranslation('FINANCE.FORM.TABLE.CUSTOMER'),
            type: ColumnType.STRING,
            accessor: 'customerName',
            cell: (row: StandaloneLink, value: any) => {
                return (
                    <Link to={`/dashboard/netlinking/customer/${row.customerId}/`}>{value}</Link>
                )
            }
        },
        {
            header: getTranslation('FINANCE.FORM.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: 'createdAt',
            cell: (row: StandaloneLink, value: any) => {
                return value.toLocaleDateString(defaultLanguage)
            }
        },
        {
            header: getTranslation('FINANCE.FORM.TABLE.ACCOUNT'),
            type: ColumnType.STRING,
            accessor: 'accountUser',
        },
        {
            header: getTranslation('FINANCE.FORM.TABLE.PROVIDER'),
            type: ColumnType.STRING,
            accessor: 'providerName',
        },
        {
            header: getTranslation('FINANCE.FORM.TABLE.PRICE_HT'),
            type: ColumnType.NUMBER,
            accessor: 'priceHt',
            cell: (row: StandaloneLink, value: any) => {
                return value.toLocaleString(
                    defaultLanguage,
                    {
                        style: 'currency',
                        currency: 'EUR'
                    }
                )
            }
        }
    ]


    if (isLoading) {
        return (
            <div className={'animate-pulse mt-4 w-full p-4 bg-gray-50 rounded'}>
                <div className={'w-full p-3.5 flex justify-between items-center'}>
                    {
                        defaultColumns.map((c, idx) => {
                            return (
                                <div key={idx} className={'w-20 h-3 rounded-lg bg-slate-500'}/>
                            )
                        })
                    }
                </div>
                {
                    defaultColumns.map((c, idx) => {
                        return (
                            <div key={idx} className={'w-full p-3.5 flex justify-between items-center'}>
                                {
                                    defaultColumns.map((c, idx) => {
                                        return <div key={idx} className={'w-32 h-2 rounded-lg bg-slate-300'}/>
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className={'w-full 2xl:w-3/5'}>
            <TableV2
                rows={props.standaloneLinks}
                columns={defaultColumns}
            >
                <PaginateAdvancedTable
                    translator={translator}
                />
            </TableV2>
        </div>
    )
}