import {getTranslation} from "../../../intl";
import {StandaloneLink} from "../../../models/standalone";
import {DataFilterGroup, Option} from "../../dummies/filter";
import React from "react";


interface FilterGroups {
    years: Option[],
    months: Option[],
    accounts: Option[],
    customers: Option[],
    providers: Option[],
}

export interface Props {
    standaloneLinks: StandaloneLink[]
    onFilter: (standaloneLinks: StandaloneLink[]) => void
    filterGroups: FilterGroups
    isLoading?: boolean
}

export function FinanceFormFilters(props: Props) {
    let isLoading = props.isLoading || false


    return (
        <DataFilterGroup
            data={props.standaloneLinks}
            onFilter={props.onFilter}
            filterGroups={[
                {
                    field: 'createdAt',
                    label: getTranslation('FINANCE.FORM.FILTERING.LABEL_YEAR'),
                    options: props.filterGroups.years,
                    operator: 'custom',
                    test: (row: StandaloneLink, search: number) => {
                        return (
                            row.createdAt.getFullYear() === search
                        )
                    }
                },
                {
                    field: 'createdAt',
                    label: getTranslation('FINANCE.FORM.FILTERING.LABEL_MONTH'),
                    options: props.filterGroups.months,
                    operator: 'custom',
                    test: (row: StandaloneLink, search: string) => {
                        return (
                            row.createdAt.toLocaleDateString('fr-FR', {month: 'long'}) === search
                        )
                    }
                },
                {
                    field: 'accountUser',
                    label: getTranslation('FINANCE.FORM.FILTERING.LABEL_ACCOUNT'),
                    options: props.filterGroups.accounts,
                    operator: 'contains'
                },
                {
                    field: 'customerName',
                    label: getTranslation('FINANCE.FORM.FILTERING.LABEL_CUSTOMER'),
                    options: props.filterGroups.customers,
                    operator: 'contains'
                },
                {
                    field: 'providerName',
                    label: getTranslation('FINANCE.FORM.FILTERING.LABEL_PROVIDER'),
                    options: props.filterGroups.providers,
                    operator: 'strictly-contains'
                },
            ]}
            isLoading={isLoading}
        />
    )
}