import React from "react";
import {Link} from "react-router-dom";

import {Account, Customer, Link as Backlink, Provider, Quote} from "../../../../models";

import {defaultLanguage, getTranslation} from "../../../../intl";


export interface Props {
    link: Backlink
    account: Account | undefined
    customer: Customer | undefined
    quote: Quote | undefined
    provider: Provider | undefined
}

export function LinkSummary(props: Props) {
    let nextAnalyze = null

    if (props.link.lastUpdate) {
        nextAnalyze = new Date()
        nextAnalyze.setDate(props.link.lastUpdate.getDate() + 30)
    }


    return (
        <div className={'relative w-full'}>
            <div className={
                'w-full sm:w-fit mb-6 p-3 md:px-10 bg-teal-100 text-teal-500 text-center rounded-lg'
            }>
                <i className={'fa-solid fa-circle-info mr-3 text-lg'}/>
                <span>{getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_NEXT_ANALYZE')} le {
                    nextAnalyze &&
                    nextAnalyze.toLocaleDateString(
                        defaultLanguage,
                        {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        }
                    )
                }</span>
            </div>

            <div className={'relative rounded-lg max-h-[70dvh] overflow-auto'}>
                <table
                    className={'relative w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'}
                >
                    <thead
                        className={'text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0'}>
                    <tr>
                        <th className={'px-6 py-4'} scope={'col'}>URL</th>
                        <th className={'px-6 py-4 hidden lg:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_PLATFORM_NAME')}
                        </th>
                        <th className={'px-6 py-4 hidden lg:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_CREATED_AT')}
                        </th>
                        <th className={'px-6 py-4'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_CUSTOMER_NAME')}
                        </th>
                        <th className={'px-6 py-4 hidden xl:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_USER_EMAIL')}
                        </th>
                        <th className={'px-6 py-4 hidden sm:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_QUOTE_REF')}
                        </th>
                        <th className={'px-6 py-4 hidden 2xl:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_INVOICE_STATUS')}
                        </th>
                        <th className={'px-6 py-4'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_PRICE_HT')}
                        </th>
                        <th className={'px-6 py-4 hidden 2xl:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LANGUAGE_NAME')}
                        </th>
                        <th className={'px-6 py-4 hidden 2xl:table-cell'} scope={'col'}>
                            Dernière synchro. GSheet:
                        </th>
                        <th className={'px-6 py-4 hidden xl:table-cell'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_UPDATED_AT')}
                        </th>
                        <th className={'px-6 py-4'} scope={'col'}>
                            {getTranslation('NETLINKING.VIEW.LINK.EDIT.LABEL_LAST_UPDATE')}
                        </th>
                    </tr>
                    </thead>

                    <tbody className={'overflow-x-scroll'}>
                    <tr className={'bg-white dark:bg-gray-800'}>
                        <td className={'px-6 py-4'}>
                            <a href={props.link.url} target={'_blank'} rel={'noreferrer'}>
                                {props.link.url}
                            </a>
                        </td>
                        <td className={'px-6 py-4 hidden lg:table-cell'}>
                            {
                                props.provider ?
                                    props.provider.name : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden lg:table-cell'}>
                            {
                                props.link.createdAt.toLocaleDateString(
                                    defaultLanguage,
                                    {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    }
                                )
                            }
                        </td>
                        <td className={'px-6 py-4'}>
                            {
                                props.customer ?
                                    <Link to={`/dashboard/netlinking/customer/${props.customer.id}/`}>
                                        {props.customer.name}
                                    </Link> : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden xl:table-cell'}>
                            {
                                props.account ?
                                    <span>{props.account.user.firstname} {props.account.user.lastname.slice(0, 1)}.</span> : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden sm:table-cell'}>
                            {
                                props.quote ?
                                    <Link to={`/dashboard/netlinking/quote/${props.quote.id}/`}>
                                        {props.quote.ref}
                                    </Link> : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden 2xl:table-cell'}>
                            {getTranslation(`INVOICE_STATUS.${props.link.invoiceStatus}.LABEL`)}
                        </td>
                        <td className={'px-6 py-4 font-medium'}>
                            {
                                props.link.priceHt ?
                                    props.link.priceHt.toLocaleString(
                                        defaultLanguage,
                                        {
                                            style: 'currency',
                                            currency: 'EUR'
                                        }
                                    ) : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden 2xl:table-cell'}>
                            {getTranslation(`LANGUAGE.${props.link.language}.LABEL`)}
                        </td>
                        <td className={'px-6 py-4 hidden 2xl:table-cell'}>
                            {
                                props.link.importedAt ?
                                    props.link.importedAt.toLocaleDateString(
                                        defaultLanguage,
                                        {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                        }
                                    ) : '-'
                            }
                        </td>
                        <td className={'px-6 py-4 hidden xl:table-cell'}>
                            {
                                props.link.updatedAt.toLocaleDateString(
                                    defaultLanguage,
                                    {
                                        day: "numeric",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    }
                                )
                            }
                        </td>
                        <td className={'px-6 py-4'} data-testid={"next-analyze"}>
                            {
                                props.link.lastUpdate ?
                                    props.link.lastUpdate.toLocaleDateString(
                                        defaultLanguage,
                                        {
                                            day: "numeric",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit"
                                        }
                                    ) :
                                    getTranslation('NETLINKING.VIEW.LINK.EDIT.SOON')
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}