import React from "react";
import {useLocation} from "react-router-dom";

import {Customer, Link, Provider, Quote} from "../../../../models";

import {Breadcrumb, JwtToken, Cache} from "@boomrank/react-components";
import {QuoteListView} from "../../views/quote/list";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    cacheLinks: Cache<Link>
    cacheCustomers: Cache<Customer>
    quotes: Quote[]
    cacheProviders: Cache<Provider>
}

export function NetlinkingQuoteListRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('NETLINKING.ROUTE.QUOTE.LIST.TITLE')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/netlinking/`,
                        content: 'Dashboard',
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/netlinking/quote/`,
                        content: getTranslation('BREADCRUMB.QUOTES')
                    },
                ]}
            />

            <QuoteListView
                token={props.token}
                cacheLinks={props.cacheLinks}
                cacheCustomers={props.cacheCustomers}
                quotes={props.quotes}
                cacheProviders={props.cacheProviders}
            />
        </>
    )
}