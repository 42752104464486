import React from "react";
import {useNavigate} from "react-router-dom";

import {Identifier, Quote,} from "../../../../models";
import {QuoteApi} from "../../../../services/quote/api";

import {Button, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {getTranslation} from "../../../../intl";


interface Props {
    token: JwtToken
    quote: Quote
    cacheUpdater: (obj: Identifier, remove: boolean) => void
    navigateTo?: string
}

export function QuoteDeleteView(props: Props) {
    let navigate = useNavigate()

    let onClick = (quote: Quote) => {
        let confirm = window.confirm(
            getTranslation('NETLINKING.VIEW.QUOTE.DELETE.CONFIRM_JS')
        )

        if (confirm) {
            QuoteApi.delete(
                props.token,
                quote.id,
            ).then(res => {
                if (res.statusCode === 204) {
                    props.cacheUpdater(quote, true)

                    if (props.navigateTo) {
                        return navigate(props.navigateTo)
                    }
                } else {
                    res = res as FormErrorResponse;
                    console.error(res.data)
                }
            })
        }
    }


    return (
        <div className={'w-full lg:w-80 py-6 px-5 border-2 border-red-100 bg-red-100 rounded-xl'}>
            <span className={'text-red-500 font-medium md:w-3/5'}>
                Suppression de la facture :
            </span>

            <Button
                data-testid={'quote-delete-btn'}
                text={getTranslation('NETLINKING.VIEW.QUOTE.DELETE.SUBMIT_BUTTON')}
                onClick={() => onClick(props.quote)}
                className={
                    'transition-all bg-red-500 text-white block mt-3 mx-auto p-2 rounded-md ' +
                    'hover:bg-red-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-gray-100'
                }
            />
        </div>
    )
}