import React from "react";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";

interface Props {
    links: StandaloneLink[]
    indexedLinks: StandaloneLink[]
}

export function TotalIndexed(props: Props) {
    return (
        <Card
            iconName={'fa-solid fa-eye'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_INDEXED.LABEL')}
            content={
                <>
                    {props.indexedLinks.length}
                </>
            }
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}