import React, {useEffect, useState} from "react";
import {FilterOperator, DataFilter} from "@boomrank/react-components";
import {GroupFilter, GroupFilters} from "./group-filters";


export interface Option {
    value: any
    active: boolean
    selected: boolean
}

export interface FilterGroup {
    field: string
    options: Option[]
    operator: FilterOperator
    label?: string
    test?: (value: any, search: any) => boolean
}

export interface Group<T> {
    field: keyof T
    filters: GroupFilter<T>[]
    label?: string
}

interface Props<T> {
    data: T[]
    filterGroups: FilterGroup[]
    onFilter?: (rows: T[]) => void
    isLoading?: boolean
}

export function DataFilterGroup<T>(props: Props<T>) {
    let isLoading = props.isLoading || false

    let dataFilter = new DataFilter<T>()

    let [groups, setGroups] = useState<Group<T>[]>([])

    let search = (groups: Group<T>[]) => {
        dataFilter.setData(props.data)
        dataFilter.setGroups(groups.map((g) => g.filters))

        if (props.onFilter) {
            props.onFilter(dataFilter.search())
        }
    }

    let initGroups = () => {
        let groups = props.filterGroups.map((group) => {
            let filters = group.options.map((option) => {
                return {
                    field: group.field as keyof T,
                    value: option.value,
                    operator: group.operator,
                    test: group.test,
                    active: option.active,
                    selected: option.selected
                }
            })

            return {
                field: group.field as keyof T,
                filters: filters,
                label: group.label
            }
        })

        search(groups)
    }

    let onSearch = (groups: Group<T>[]) => {
        setGroups(groups)
        search(groups)
    }

    useEffect(() => {
        initGroups()
    }, [props.data])


    if (props.filterGroups.length > 0) {
        return (
            <div className={'w-full'}>
                {
                    props.filterGroups.map((group, idx) => {
                        return (
                            <GroupFilters
                                key={idx}
                                group={group}
                                groups={groups}
                                onSearch={onSearch}
                                isLoading={isLoading}
                            />
                        )
                    })
                }
            </div>
        )
    }

    return <></>
}