import { endpoints as boomrankEndpoints } from "@boomrank/react-components";

let BACKEND_API = process.env.REACT_APP_BACKEND_API

export const endpoints = {
    ...boomrankEndpoints,
    account: {
        list: BACKEND_API+'/api/account/',
    },
    export: {
        csv: BACKEND_API+'/api/export/csv/',
    },
    customer: {
        list: BACKEND_API+'/api/customer/',
        create: BACKEND_API+'/api/customer/',
        update: BACKEND_API+'/api/customer/:pk/',
        retrieve: BACKEND_API+'/api/customer/:pk/',
        delete: BACKEND_API+'/api/customer/:pk/',
        hasAccounts: {
            list: BACKEND_API+'/api/customer/:customerId/has_accounts/',
            create: BACKEND_API+'/api/customer/:customerId/has_accounts/',
            update: BACKEND_API+'/api/customer/:customerId/has_accounts/:pk/',
        },
    },
    accountHasCustomer: {
        list: BACKEND_API+'/api/account_has_customer/',
    },
    provider: {
        list: BACKEND_API+'/api/provider/',
    },
    quote: {
        list: BACKEND_API+'/api/quote/',
        create: BACKEND_API+'/api/quote/',
        update: BACKEND_API+'/api/quote/:pk/',
        delete: BACKEND_API+'/api/quote/:pk/',
    },
    link: {
        list: BACKEND_API+'/api/link/',
        update: BACKEND_API+'/api/link/:pk/',
        delete: BACKEND_API+'/api/link/:pk/',
        bulk: {
            analyze: BACKEND_API+'/api/link/bulk/analysis/',
            checkAnalysis: BACKEND_API+'/api/link/bulk/check_analysis/',
            isIndexed: BACKEND_API+'/api/link/bulk/is_indexed/',
            update: BACKEND_API+'/api/link/bulk/update_links/',
            delete: BACKEND_API+'/api/link/bulk/delete/',
        },
    },
    notification: {
        list: BACKEND_API+'/api/notification/',
        update: BACKEND_API+'/api/notification/:objectId/',
    }
}