import React from "react";
import {JwtToken, Cache} from "@boomrank/react-components";
import {Customer, Quote, Link as Backlink, AccountHasCustomer} from "../../../../models";
import {CustomerTableView} from "./table";
import {getTranslation} from "../../../../intl";
import {Card} from "../../../dummies/card";


interface Props {
    token: JwtToken
    customers: Customer[]
    accountHasCustomers: AccountHasCustomer[]
    cacheLinks: Cache<Backlink>
    cacheQuotes: Cache<Quote>
}

export function CustomerListView(props: Props) {
    return (
        <div className={'relative w-full mt-4 bg-white p-8 rounded-lg shadow-sm 2xl:flex 2xl:gap-x-16'}>
            <div className={'w-full h-fit 2xl:w-1/4 mb-20 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-1 gap-3'}>
                <Card
                    content={
                        <>{props.customers.length}</>
                    }
                    iconName={'fa-solid fa-star'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.CUSTOMER')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <>{props.cacheLinks.values().length}</>
                    }
                    iconName={'fa-solid fa-link'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.LINK')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <>{props.cacheQuotes.values().length}</>
                    }
                    iconName={'fa-solid fa-receipt'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.QUOTE')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />
            </div>

            <div className={'w-full 2xl:w-3/4'}>
                <CustomerTableView
                    token={props.token}
                    customers={props.customers}
                    accountHasCustomers={props.accountHasCustomers}
                    cacheLinks={props.cacheLinks}
                    cacheQuotes={props.cacheQuotes}
                    columns={[
                        'name',
                        'createdAt',
                        'importedAt',
                        'nbLinks',
                        'brokenLinks',
                        'budget'
                    ]}
                />
            </div>
        </div>
    )
}