import React from "react";
import {Link} from "react-router-dom";
import {
    JwtToken,
    Cache,
    Column,
    ColumnType,
    PaginateAdvancedTable,
    FullTable
} from "@boomrank/react-components";

import {
    Customer,
    Quote,
    Link as Backlink,
    AccountHasCustomer
} from "../../../../models";
import {StandaloneCustomer} from "../../../../models/standalone";
import {StandaloneCustomerFactory} from "../../../../services/customer/factory";
import {ExportApi} from "../../../../services/export/api";

import {defaultLanguage, getTranslation, translator} from "../../../../intl";


interface Props {
    token: JwtToken
    customers: Customer[]
    accountHasCustomers: AccountHasCustomer[]
    cacheLinks: Cache<Backlink>
    cacheQuotes: Cache<Quote>
    columns: (keyof StandaloneCustomer)[]
}

export function CustomerTableView(props: Props) {
    let defaultColumns: Column<StandaloneCustomer>[] = [
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.NAME'),
            type: ColumnType.STRING,
            accessor: 'name',
            cell: (row: StandaloneCustomer, value: any) => {
                return (
                    <Link to={`/dashboard/netlinking/customer/${row.id}/`}>{value}</Link>
                )
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.CREATED_AT'),
            type: ColumnType.DATE,
            accessor: 'createdAt',
            cell: (row: StandaloneCustomer, value: any) => {
                return value.toLocaleDateString(
                    defaultLanguage,
                    {day: '2-digit', month: '2-digit', year: 'numeric'}
                )
            }
        },
        {
            header: 'Dernière synchro.',
            type: ColumnType.DATE,
            accessor: 'importedAt',
            cell: (row: StandaloneCustomer, value: any) => {
                if (value) {
                    return value.toLocaleDateString(
                        defaultLanguage,
                        {day: '2-digit', month: '2-digit', year: 'numeric'}
                    )
                }
                return '-'
            }
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.LINK'),
            type: ColumnType.NUMBER,
            accessor: 'nbLinks',
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.BROKEN_LINK'),
            type: ColumnType.NUMBER,
            accessor: 'brokenLinks',
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.QUOTE'),
            type: ColumnType.NUMBER,
            accessor: 'nbQuotes',
        },
        {
            header: getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.TOTAL_BUDGET'),
            type: ColumnType.NUMBER,
            accessor: 'budget',
            cell: (row: StandaloneCustomer, value: any) => {
                return value.toLocaleString(
                    defaultLanguage,
                    {
                        style: 'currency',
                        currency: 'EUR'
                    }
                )
            }
        }
    ]

    let formatter = (customers: Customer[]) : StandaloneCustomer[] => {
        return customers.map((customer) => {
           return StandaloneCustomerFactory.fromCustomer(
               customer,
               props.accountHasCustomers,
               props.cacheQuotes.values(),
               props.cacheLinks.values()
           )
        })
    }

    let onClickExport = (customers: StandaloneCustomer[]) => {
        if (customers.length > 0) {
            ExportApi.csv(props.token, customers)
        }
    }

    return (
        <FullTable
            rows={props.customers}
            formatter={formatter}
            columns={defaultColumns}
            showColumns={props.columns}
            localStorageColumns={'bc-customer-table'}
            onClickExport={onClickExport}
            labelExport={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.EXPORT')}
            infoMessage={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.INFO_MESSAGE')}
            labelOptionAll={getTranslation('NETLINKING.VIEW.CUSTOMER.TABLE.ALL')}
        >
            <PaginateAdvancedTable
                translator={translator}
            />
        </FullTable>
    )
}