export interface Identifier {
    id: number | string
}

export enum LastType {
    TXT = 1,
    IMG = 2,
}

export enum InvoiceStatus {
    MONTHLY = 1,
    DIRECT = 2,
    DIRECT_PAYED = 3,
}

export enum Language {
    FRENCH = 1,
    ITALIAN = 2,
    SPANISH = 3,
    GERMAN = 4,
    ENGLISH = 5,
    NETHERLAND = 6,
}

export class User {
    id: number = 0
    email: string = ''
    firstname: string = ''
    lastname: string = ''
    username: string = ''
    isAdmin: boolean = false
    isActive: boolean = false
    permissions: string[] = []
}

export class Account implements Identifier {
    id: number = 0
    user: User = new User()
    createdAt: Date = new Date()
}

export class AccountHasCustomer {
    id: number = 0
    createdAt: Date = new Date()
    accountId: number = 0
    customerId: number = 0
    notificationEnabled: boolean = true
}

export class Customer implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
    accountIds: number[] = []
}

export class Link implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    updatedAt: Date = new Date()
    importedAt: Date | null = null

    key: string = ''
    language: Language = Language.FRENCH
    priceHt: number | null = null
    url: string = ''
    broken: boolean = false
    underCorrection: boolean = false
    invoiceStatus: InvoiceStatus = InvoiceStatus.MONTHLY

    targetExpression: string = ''
    targetRegex: string | null = null
    targetContent: string | null = null
    targetUrl: string = ''

    requestedRegex: string | null = null
    requestedContent: string | null = null
    requestedUrl: string = ''

    isOk: boolean = false
    isValid: boolean | null = null
    isIndexed: boolean | null = null
    nbRedirect: number | null = null

    lastUrls: string[] | null = null
    urlValid: boolean | null = null

    lastHeaders: string | null = null
    headersValid: boolean | null = null

    lastMeta: string | null = null
    metaValid: boolean | null = null

    lastRel: string | null = null
    relValid: boolean | null = null

    lastHttpCode: number | null = null
    httpCodeValid: boolean | null = null

    lastContent: string | null = null
    contentValid: boolean | null = null

    lastType: LastType | null = null
    lastUpdate: Date | null = null

    comment: string = ''

    accountId: number = 0
    customerId: number = 0
    providerId: number = 0
    quoteId: number | null = null
}

export class Provider implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    name: string = ''
}

export class Quote implements Identifier {
    id: number = 0
    createdAt: Date = new Date()
    refDate: Date = new Date()
    ref: string = ''
    customerId: number = 0
}

export class Notification implements Identifier {
    id: string = ''
    createdAt: Date = new Date()
    username: string = ''
    customerId: number = 0
    customerName: string = ''
    code: string = ''
    args: (string | number)[] = []
    markAsRead: boolean = false
}