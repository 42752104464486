import React, {useEffect, useState} from "react";
import {Cache, JwtToken} from "@boomrank/react-components";

import {
    Account,
    Customer,
    Quote,
    Link as Backlink,
    Provider,
    Notification, Identifier
} from "../../../models";
import {StandaloneLink} from "../../../models/standalone";
import {StandaloneLinkFactory} from "../../../services/link/factory";

import {FinanceForm} from "../forms";


interface Props {
    token: JwtToken
    links: Backlink[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
    cacheCustomers: Cache<Customer>
    cacheQuotes: Cache<Quote>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function FinanceHomeView(props: Props) {
    let [standaloneLinks, setStandaloneLinks] = useState<StandaloneLink[]>([])

    let linkFormatter = (links: Backlink[]) => {
        let standaloneLinks = links.map((link) => {
            return StandaloneLinkFactory.fromLink(
                link,
                props.cacheCustomers,
                props.cacheProviders,
                props.cacheAccounts,
                props.cacheQuotes,
            )
        })

        setStandaloneLinks(standaloneLinks)
    }

    useEffect(() => {
        linkFormatter(props.links)
    }, [props.links]);


    return (
        <FinanceForm
            token={props.token}
            standaloneLinks={standaloneLinks}
        />
    )
}
