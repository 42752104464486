import React from "react";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";

interface Props {
    links: StandaloneLink[]
}

export function TotalLink(props: Props) {
    return (
        <Card
            iconName={'fa-solid fa-link'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_LINK.LABEL')}
            content={<>{props.links.length}</>}
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}