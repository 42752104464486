import React from "react";
import {useLocation} from "react-router-dom";

import {Account, Customer, Identifier, Link as Backlink, Notification, Provider, Quote} from "../../../models";

import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {FinanceHomeView} from "../views";
import {getTranslation} from "../../../intl";


interface Props {
    token: JwtToken
    links: Backlink[]
    cacheAccounts: Cache<Account>
    cacheProviders: Cache<Provider>
    cacheCustomers: Cache<Customer>
    cacheQuotes: Cache<Quote>
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function FinanceHomeRoute(props: Props) {
    let location = useLocation()
    document.title = getTranslation('FINANCE.ROUTE.HOME.TITLE')

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/finance/`, content: 'Finance'},
                ]}
            />

            <FinanceHomeView
                token={props.token}
                links={props.links}
                cacheAccounts={props.cacheAccounts}
                cacheProviders={props.cacheProviders}
                cacheCustomers={props.cacheCustomers}
                cacheQuotes={props.cacheQuotes}
                cacheNotifications={props.cacheNotifications}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}