import React, {useEffect, useState} from "react";

import {ButtonGroup, Cache, JwtToken} from "@boomrank/react-components";
import {
    Quote,
    Link as Backlink,
    Customer,
    Account,
    AccountHasCustomer,
    Identifier
} from "../../../models";

import {StandaloneCustomer} from "../../../models/standalone";
import {StandaloneCustomerFactory} from "../../../services/customer/factory";

import {Card} from "../../dummies/card";
import {getTranslation} from "../../../intl";
import {StandaloneCustomerTableView} from "./customer/standaloneTable";


interface Props {
    token: JwtToken
    me: Account
    cacheCustomers: Cache<Customer>
    cacheQuotes: Cache<Quote>
    cacheLinks: Cache<Backlink>
    cacheAccountHasCustomers: Cache<AccountHasCustomer>
    cacheUpdater: (obj: Identifier) => void
}

export function NetlinkingHomeView(props: Props) {
    let [standaloneCustomers, setStandaloneCustomers] = useState<StandaloneCustomer[]>([])
    let [showAll, setShowAll] = useState<boolean>(false)

    let customerFormatter = (customers: Customer[], all: boolean) => {
        let standalone = customers.map((customer) => {
            return StandaloneCustomerFactory.fromCustomer(
                customer,
                props.cacheAccountHasCustomers.values(),
                props.cacheQuotes.values(),
                props.cacheLinks.values(),
            )
        })

        if (all) {
            setStandaloneCustomers([
                ...standalone.filter((sc) =>
                    sc.accountIds.indexOf(props.me.id) !== -1
                ).sort((a, b) => a.budget - b.budget),
                ...standalone.filter((sc) =>
                    sc.accountIds.indexOf(props.me.id) === -1
                ).sort((a, b) => b.budget - a.budget)
            ])
        } else {
            setStandaloneCustomers(
                standalone.filter((sc) =>
                    sc.accountIds.indexOf(props.me.id) !== -1
                ).sort((a, b) => b.budget - a.budget)
            )
        }
    }

    let getLinksByDate = (range?: number) => {
        return props.cacheLinks.values().reduce((links: Backlink[], link: Backlink) => {
            let selectedCustomers = standaloneCustomers.filter(customer => customer.id === link.customerId)

            if (selectedCustomers.length > 0) {
                if (range) {
                    let currentYear = new Date()
                    let sixteenMonthsAgo = new Date(currentYear.setMonth(currentYear.getMonth() - range))

                    if (link.createdAt >= sixteenMonthsAgo) {
                        links.push(link)
                    }
                } else {
                    if (link.createdAt.getFullYear() === new Date().getFullYear()) {
                        links.push(link)
                    }
                }
            }

            return links
        }, [])
    }

    let getSpendByDate = (range?: number) => {
        let links = getLinksByDate()

        if (range) {
            links = getLinksByDate(range)
        }

        return links.reduce((sum, link) => sum + link.priceHt!, 0)
            .toLocaleString(
                'fr-FR',
                {'style': 'currency', 'currency': 'EUR'}
            )
    }

    let allCustomers = (all: boolean) => {
        setShowAll(all)
        customerFormatter(props.cacheCustomers.values(), all)
    }

    useEffect(() => {
        customerFormatter(props.cacheCustomers.values(), showAll)
    }, [props.cacheCustomers, props.cacheAccountHasCustomers]);


    return (
        <div className={'relative w-full mt-4 bg-white p-8 rounded-lg shadow-sm 2xl:flex 2xl:gap-x-16'}>
            <div className={'w-full h-fit 2xl:w-1/4 mb-20 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-1 gap-3'}>
                <Card
                    content={
                        <>{standaloneCustomers.length}</>
                    }
                    iconName={'fa-solid fa-star'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.CUSTOMER')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <>{
                            standaloneCustomers.reduce((total, customer) => {
                                return total + customer.nbLinks
                            }, 0)
                        }</>
                    }
                    iconName={'fa-solid fa-link'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.LINK')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <div className={'w-full flex justify-between items-end'}>
                            <span>{getLinksByDate().length}</span>
                            <span className={'text-base text-light'}>{getSpendByDate()}</span>
                        </div>
                    }
                    iconName={'fa-solid fa-calendar-days'}
                    title={`Liens ajoutés en ${new Date().getFullYear()}`}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <div className={'w-full flex justify-between items-end'}>
                            <span>{getLinksByDate(16).length}</span>
                            <span className={'text-base text-light'}>{getSpendByDate(16)}</span>
                        </div>
                    }
                    iconName={'fa-solid fa-bars-progress'}
                    title={`Liens ajoutés sur 16 mois`}
                    iconColor={'text-slate-700 bg-slate-50'}
                />

                <Card
                    content={
                        <>{
                            standaloneCustomers.reduce((total, customer) => {
                                return total + customer.nbQuotes
                            }, 0)
                        }</>
                    }
                    iconName={'fa-solid fa-receipt'}
                    title={getTranslation('NETLINKING.VIEW.HOME.CARD_HEADER.QUOTE')}
                    iconColor={'text-slate-700 bg-slate-50'}
                />
            </div>

            <div className={'w-full 2xl:w-3/4'}>
                <ButtonGroup
                    buttons={[
                        {
                            text: 'Mes clients',
                            icon: 'fa-regular fa-star',
                            onClick: () => allCustomers(false)
                        },
                        {
                            text: 'Tous',
                            icon: 'fa-solid fa-users',
                            onClick: () => allCustomers(true)
                        },
                    ]}
                />

                {
                    standaloneCustomers.length > 0 &&
                    <StandaloneCustomerTableView
                        me={props.me}
                        token={props.token}
                        standaloneCustomers={standaloneCustomers}
                        cacheAccountHasCustomers={props.cacheAccountHasCustomers}
                        columns={[
                            'name',
                            'issuesLinks',
                            'brokenLinks',
                            'budget',
                            'accountId'
                        ]}
                        localStorageColumns={'bc-user-customer-table'}
                        cacheUpdater={props.cacheUpdater}
                    />
                }
            </div>
        </div>
    )
}
