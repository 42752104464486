import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import {JwtToken, PlaceHolder, Cache, Breadcrumb} from "@boomrank/react-components";

import {
    Account,
    AccountHasCustomer,
    Customer,
    Identifier,
    Link,
    Notification,
    Provider,
    Quote
} from "../models";

import {AuthApi} from "../services/auth/api";
import {MeSuccess} from "../services/auth/responses";

import {ProviderApi} from "../services/provider/api";
import {ProviderListResponse} from "../services/provider/responses";
import {ProviderFactory} from "../services/provider/factory";
import {ProviderFragment} from "../services/provider/fragments";

import {CustomerApi} from "../services/customer/api";
import {CustomerListResponse} from "../services/customer/responses";
import {CustomerFactory} from "../services/customer/factory";
import {CustomerFragment} from "../services/customer/fragments";

import {AccountHasCustomerApi} from "../services/account-has-customer/api";
import {AccountHasCustomerListResponse} from "../services/account-has-customer/responses";
import {AccountHasCustomerFactory} from "../services/account-has-customer/factory";

import {LinkApi} from "../services/link/api";
import {LinkListResponse} from "../services/link/responses";
import {LinkFactory} from "../services/link/factory";

import {AccountApi} from "../services/account/api";
import {AccountListResponse} from "../services/account/responses";
import {AccountFactory} from "../services/account/factory";
import {AccountFragment} from "../services/account/fragments";

import {QuoteApi} from "../services/quote/api";
import {QuoteListResponse} from "../services/quote/responses";
import {QuoteFactory} from "../services/quote/factory";
import {QuoteFragment} from "../services/quote/fragments";

import {NotificationApi} from "../services/notification/api";
import {NotificationListResponse} from "../services/notification/responses";
import {NotificationFactory} from "../services/notification/factory";
import {NotificationFragment} from "../services/notification/fragments";

import {Template} from "./dummies/template";
import {NetlinkingDashboard} from "./netlinking";
import {FinanceDashboard} from "./finance";
import {NotificationHomeRoute} from "./notification/routes";
import {getTranslation} from "../intl";


interface Props {
    token: JwtToken
}

export function Dashboard (props: Props) {
    let location = useLocation()

    let [me, setMe] = useState<Account | null>(null);

    let [cacheAccounts, setCacheAccounts] = useState<Cache<Account>>(new Cache<Account>('id'))
    let [cacheCustomers, setCacheCustomers] = useState<Cache<Customer>>(new Cache<Customer>('id'))
    let [cacheAccountHasCustomers, setCacheAccountHasCustomers] = useState<Cache<AccountHasCustomer>>(new Cache<AccountHasCustomer>('id'));
    let [cacheProviders, setCacheProviders] = useState<Cache<Provider>>(new Cache<Provider>('id'))
    let [cacheLinks, setCacheLinks] = useState<Cache<Link>>(new Cache<Link>('id'))
    let [cacheQuotes, setCacheQuotes] = useState<Cache<Quote>>(new Cache<Quote>('id'))
    let [cacheNotifications, setCacheNotifications] = useState<Cache<Notification>>(new Cache<Notification>('id'))

    let [customersLoaded, setCustomersLoaded] = useState(false)
    let [accountsLoaded, setAccountsLoaded] = useState(false)
    let [accountHasCustomersLoaded, setAccountHasCustomersLoaded] = useState(false)
    let [providersLoaded, setProvidersLoaded] = useState(false)
    let [linksLoaded, setLinksLoaded] = useState(false)
    let [quotesLoaded, setQuotesLoaded] = useState(false)
    let [notificationsLoaded, setNotificationsLoaded] = useState(false)

    let cacheUpdater = (obj: Identifier, remove = false) => {
        let cache: Cache<any> | undefined
        let setCache: Dispatch<SetStateAction<Cache<any>>> | undefined

        if (obj instanceof Account) {
            cache = cacheAccounts
            setCache = setCacheAccounts
        }
        if (obj instanceof Customer) {
            cache = cacheCustomers
            setCache = setCacheCustomers
        }
        if (obj instanceof AccountHasCustomer) {
            cache = cacheAccountHasCustomers
            setCache = setCacheAccountHasCustomers
        }
        if (obj instanceof Provider) {
            cache = cacheProviders
            setCache = setCacheProviders
        }
        if (obj instanceof Link) {
            cache = cacheLinks
            setCache = setCacheLinks
        }
        if (obj instanceof Quote) {
            cache = cacheQuotes
            setCache = setCacheQuotes
        }
        if (obj instanceof Notification) {
            cache = cacheNotifications
            setCache = setCacheNotifications
        }

        if (cache !== undefined && setCache !== undefined) {
            cache.add(obj)
            if (remove) {
                cache.delete(obj)
            }
            setCache({
                ...cache
            })
        }
    }

    let getMe = () => {
        AuthApi.getMe(props.token)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as MeSuccess
                    let me = AccountFactory.fromFragment(response.data)
                    setMe(me)
                }
            })
    }

    let getAccounts = (url?: string) => {
        AccountApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as AccountListResponse
                    let accounts = response.data.results.map((account: AccountFragment) => {
                        return AccountFactory.fromFragment(account)
                    })
                    let cache = new Cache<Account>('id').add(accounts)
                    setCacheAccounts(cacheAccounts => cacheAccounts.merge(cache))

                    if (response.data.next) {
                        return getAccounts(response.data.next)
                    }
                }
                setAccountsLoaded(true)
            })
    }

    let getCustomers = (url?: string) => {
        CustomerApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as CustomerListResponse
                    let customers = response.data.results.map((customer: CustomerFragment) => {
                        return CustomerFactory.fromFragment(customer)
                    })
                    let cache = new Cache<Customer>('id').add(customers)
                    setCacheCustomers(cacheCustomers => cacheCustomers.merge(cache))

                    if (response.data.next) {
                        return getCustomers(response.data.next)
                    }
                }
                setCustomersLoaded(true)
            })
    }

    let getAccountHasCustomers = (url?: string) => {
        AccountHasCustomerApi.list(props.token, url).then((res) => {
            if(res.statusCode >= 200 && res.statusCode < 300) {
                res = res as AccountHasCustomerListResponse;

                let accountHasCustomers = res.data.results.map((fragment) => {
                    return AccountHasCustomerFactory.fromFragment(fragment);
                })
                let cache = new Cache<AccountHasCustomer>('id').add(accountHasCustomers)
                setCacheAccountHasCustomers(cacheAccountHasCustomers => cacheAccountHasCustomers.merge(cache))

                if (res.data.next) {
                    return getAccountHasCustomers(res.data.next);
                }
            }
            setAccountHasCustomersLoaded(true)
        })
    }

    let getProviders = (url?: string) => {
        ProviderApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as ProviderListResponse
                    let providers = response.data.results.map((provider: ProviderFragment) => {
                        return ProviderFactory.fromFragment(provider)
                    })
                    let cache = new Cache<Provider>('id').add(providers)
                    setCacheProviders(cacheProviders => cacheProviders.merge(cache))

                    if (response.data.next) {
                        return getProviders(response.data.next)
                    }
                }
                setProvidersLoaded(true)
            })
    }

    let getLinks = (url?: string) => {
        LinkApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as LinkListResponse
                    let links = response.data.results.map((link: any) => {
                        return LinkFactory.fromFragment(link)
                    })
                    let cache = new Cache<Link>('id').add(links)
                    setCacheLinks(cacheLinks => cacheLinks.merge(cache))

                    if (response.data.next) {
                        return getLinks(response.data.next)
                    }
                }
                setLinksLoaded(true)
            })
    }

    let getQuotes = (url?: string) => {
        QuoteApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as QuoteListResponse
                    let quotes = response.data.results.map((quote: QuoteFragment) => {
                        return QuoteFactory.fromFragment(quote)
                    })
                    let cache = new Cache<Quote>('id').add(quotes)
                    setCacheQuotes(cacheQuotes => cacheQuotes.merge(cache))

                    if (response.data.next) {
                        return getQuotes(response.data.next)
                    }
                }
                setQuotesLoaded(true)
            })
    }

    let getNotifications = (url?: string) => {
        NotificationApi.list(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as NotificationListResponse
                    let notifications = response.data.results.map((notification: NotificationFragment) => {
                        return NotificationFactory.fromFragment(notification)
                    })
                    let cache = new Cache<Notification>('id').add(notifications)
                    setCacheNotifications(cacheNotifications => cacheNotifications.merge(cache))

                    if (response.data.next) {
                        return getNotifications(response.data.next)
                    }
                }
                setNotificationsLoaded(true)
            })
    }

    useEffect(() => {
        getMe()
        getAccounts()
        getCustomers()
        getAccountHasCustomers()
        getProviders()
        getLinks()
        getQuotes()
        getNotifications()
    }, [])

    if (!me) {
        return (
            <Template me={new Account()}>
                <Breadcrumb
                    location={location}
                    links={[
                        {
                            to: `/dashboard/netlinking/`,
                            content:'Dashboard',
                            title: getTranslation('BREADCRUMB.HOMEPAGE')
                        },
                    ]}
                />

                <div className={'relative w-full mt-4 bg-white p-8 rounded-lg shadow-sm'}>
                    <PlaceHolder className={'h-32'} />
                </div>
            </Template>
        )
    }

    if (
        providersLoaded &&
        customersLoaded &&
        linksLoaded &&
        accountsLoaded &&
        quotesLoaded &&
        notificationsLoaded
    ) {
        return (
            <Template me={me}>
                <Routes>
                    <Route index element={
                        <Navigate to={'/dashboard/netlinking/'} />
                    } />
                    <Route
                        path={'/netlinking/*'}
                        element={
                            <NetlinkingDashboard
                                token={props.token}
                                me={me}
                                cacheAccounts={cacheAccounts}
                                cacheCustomers={cacheCustomers}
                                cacheProviders={cacheProviders}
                                cacheLinks={cacheLinks}
                                cacheQuotes={cacheQuotes}
                                cacheAccountHasCustomers={cacheAccountHasCustomers}
                                cacheNotifications={cacheNotifications}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'/finance/*'}
                        element={
                            <FinanceDashboard
                                token={props.token}
                                cacheAccounts={cacheAccounts}
                                cacheCustomers={cacheCustomers}
                                cacheProviders={cacheProviders}
                                cacheLinks={cacheLinks}
                                cacheQuotes={cacheQuotes}
                                cacheNotifications={cacheNotifications}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                    <Route
                        path={'notifications'}
                        element={
                            <NotificationHomeRoute
                                token={props.token}
                                me={me}
                                cacheNotifications={cacheNotifications}
                                cacheUpdater={cacheUpdater}
                            />
                        }
                    />
                </Routes>
            </Template>
        )
    }

    return (
        <Template me={new Account()}>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/netlinking/`,
                        content:'Dashboard',
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                ]}
            />

            <div className={'relative w-full mt-4 bg-white p-8 rounded-lg shadow-sm'}>
                <PlaceHolder className={'h-32'} />
            </div>
        </Template>
    )
}