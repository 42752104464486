import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";
import {endpoints} from "../../api";
import {
    AccountHasCustomerCreateResponse,
    AccountHasCustomerListResponse,
    AccountHasCustomerUpdateResponse
} from "../../account-has-customer/responses";


export class CustomerHasAccountApi {
    static list(
        token: JwtToken,
        customerId: number,
        baseUrl?: string
    ): Promise<FormErrorResponse | AccountHasCustomerListResponse> {
        let url = endpoints.customer.hasAccounts.list
            .replace(':customerId', customerId.toString());

        if (baseUrl) {
            url += baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static update(
        token: JwtToken,
        customerId: number,
        pk: number,
        notificationEnabled: boolean
    ): Promise<FormErrorResponse | AccountHasCustomerUpdateResponse> {
        let url = endpoints.customer.hasAccounts.update
            .replace(':customerId', customerId.toString())
            .replace(':pk', pk.toString());
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    notification_enabled: notificationEnabled,
                })
            }
        );
    }

    static create(
        token: JwtToken,
        customerId: number,
        notificationEnabled: boolean
    ): Promise<FormErrorResponse | AccountHasCustomerCreateResponse> {
        let url = endpoints.customer.hasAccounts.create
            .replace(':customerId', customerId.toString())
        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    notification_enabled: notificationEnabled,
                })
            }
        );
    }
}
