import React from "react";

interface Props {
    iconName: string
    title: string
    content: React.ReactElement | React.ReactElement[]
    iconColor?: string
}

export function Card(props: Props) {
    let iconColor = props.iconColor || 'text-teal-400 bg-teal-50'

    return (
        <div className={`relative h-fit p-3 px-4 rounded-lg text-xl lg:text-2xl flex flex-row items-center ${iconColor} shadow-sm`}>
            <i className={`${props.iconName} ${iconColor} py-2 w-16 text-center rounded-md`} />

            <div className={`w-full text-right ${iconColor}`}>
                <div>
                    {props.content}
                </div>
                <div className={'text-base font-light'}>
                    {props.title}
                </div>
            </div>
        </div>
    )
}