import {CustomerFragment} from "./fragments";
import {AccountHasCustomer, Customer, Link, Quote} from "../../models";
import {StandaloneCustomer} from "../../models/standalone";


export class CustomerFactory {
    static fromFragment = (fragment: CustomerFragment): Customer => {
        let customer = new Customer()
        customer.id = fragment.id
        customer.name = fragment.name
        customer.createdAt = new Date(fragment.created_at)
        return customer
    }
}


export class StandaloneCustomerFactory {
    static fromCustomer = (
        customer: Customer,
        accountHasCustomers: AccountHasCustomer[],
        quotes: Quote[],
        links: Link[],
    ): StandaloneCustomer => {
        let allLinks = links.filter((l) => l.customerId === customer.id)
        let issuesLinks = allLinks.filter((l) => !l.isValid && !l.broken).length
        let brokenLinks = allLinks.filter((l) => l.broken).length
        let nbQuotes = quotes.filter((q) => q.customerId === customer.id).length

        let budget = allLinks.reduce((total, link) => {
                let price = link.priceHt
                if (!price) {
                    price = 0
                }
                return total + price
            }, 0
        )

        let importedAt : null | Date = null
        if (allLinks.length > 0) {
            allLinks.forEach((l) => {
                if (l.importedAt) {
                    if (importedAt) {
                        if (importedAt.valueOf() > l.importedAt.valueOf()) {
                            importedAt = l.importedAt
                        }
                    } else {
                        importedAt = l.importedAt
                    }
                }
            })
        }

        let standaloneCustomer = {...customer} as StandaloneCustomer
        standaloneCustomer.nbLinks = allLinks.length
        standaloneCustomer.brokenLinks = brokenLinks
        standaloneCustomer.issuesLinks = issuesLinks
        standaloneCustomer.nbQuotes = nbQuotes
        standaloneCustomer.budget = budget
        standaloneCustomer.importedAt = importedAt

        let customerHasAccounts = accountHasCustomers
            .filter(ahc => ahc.customerId === customer.id)

        if (customerHasAccounts) {
            standaloneCustomer.accountIds = customerHasAccounts.map(ahc => ahc.accountId)
            standaloneCustomer.accountHasCustomers = customerHasAccounts
        }

        let accountIds = allLinks.map((link) => link.accountId)
        if (accountIds.length > 0) {
            standaloneCustomer.accountId = accountIds[0]
        }

        return standaloneCustomer
    }
}