import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {Notification, Account, Identifier} from "../../../models";
import {Breadcrumb, Cache, JwtToken} from "@boomrank/react-components";
import {NotificationListView} from "../views/list";


interface Props {
    token: JwtToken
    me: Account
    cacheNotifications: Cache<Notification>
    cacheUpdater: (obj: Identifier) => void
}

export function NotificationHomeRoute (props: Props) {
    let location = useLocation()
    document.title = 'Notifications | Backlink Checker';

    let [notifications, setNotifications] = useState<Notification[]>([])

    let getNotifications = () => {
        let notifications = props.cacheNotifications.values()
            .reduce(
                (notifications: Notification[], notification: Notification) => {
                    if (notification.username === props.me.user.username) {
                        notifications.push(notification)
                    }
                    return notifications
                }, []
            )

        setNotifications(notifications)
    }

    useEffect(() => {
        getNotifications()
    }, [props.cacheNotifications]);


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {to: `/dashboard/`, content: 'Dashboard'},
                    {to: `/dashboard/notifications/`, content: 'Notifications'},
                ]}
            />

            <NotificationListView
                token={props.token}
                notifications={notifications}
                cacheUpdater={props.cacheUpdater}
            />
        </>
    )
}