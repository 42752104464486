import React from "react";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";
import {defaultLanguage} from "../../../../intl";


interface Props {
    links: StandaloneLink[]
}

interface GroupByProviders {
    [e: string]: {
        provider: string
        sum: number
    }
}
export function BestProvider(props: Props) {
    let groupByProviders = props.links.reduce((sbp: GroupByProviders, link: StandaloneLink) => {
        let providerId = link.providerId.toString()

        if (Object.keys(sbp).indexOf(link.providerId.toString()) === -1) {
            sbp[providerId] = {
                provider: link.providerName,
                sum: 0
            }
        }
        sbp[providerId].sum = sbp[providerId].sum + (link.priceHt || 0)
        return sbp
    }, {})

    let topProvider = {
        provider: '-',
        sum: 0
    }
    Object.values(groupByProviders).forEach(provider => {
        if(provider.sum > topProvider.sum) {
            topProvider = provider
        }
    })

    return (
        <Card
            iconName={'fa-solid fa-ranking-star'}
            title={topProvider.provider}
            content={
                <>
                    {
                        topProvider.sum.toLocaleString(
                            defaultLanguage,
                            {
                                style: 'currency',
                                currency: 'EUR'
                            }
                        )
                    }
                </>
            }
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}