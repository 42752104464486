import React, {useEffect, useState} from "react";
import classNames from "classnames";

import {Notification, Identifier} from "../../../models";

import {NotificationApi} from "../../../services/notification/api";
import {NotificationUpdateResponse} from "../../../services/notification/responses";
import {NotificationFactory} from "../../../services/notification/factory";

import {Button, JwtToken, PlaceHolder} from "@boomrank/react-components";
import {NotificationTableView} from "./table";
import {getTranslation} from "../../../intl";


interface Props {
    token: JwtToken
    notifications: Notification[]
    cacheUpdater: (obj: Identifier) => void
}

export function NotificationListView(props: Props) {
    let [loading, setLoading] = useState(false)

    let [notifications, setNotifications] = useState<Notification[]>([])
    let [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([])
    let [showNotifications, setShowNotifications] = useState<boolean>(false)


    let sortByDate = (notifications: Notification[]) => {
        notifications.sort((a: Notification, b: Notification) =>
            a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
        );
    }

    let notificationUpdater = (notification: Notification) => {
        NotificationApi.update(
            props.token.access,
            notification.id,
            !notification.markAsRead
        ).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
                res = res as NotificationUpdateResponse
                props.cacheUpdater(NotificationFactory.fromFragment(res.data))
            }
        })
    }

    let notificationsReadUpdater = (markAsRead: boolean) => {
        let notifications = props.notifications.filter(n => n.markAsRead === markAsRead)

        notifications.forEach((notification: Notification) => {
            notificationUpdater(notification)
        })
    }

    let handleNotifications = () => {
        setLoading(true)

        let unreadNotificationList: Notification[] = []
        let notificationList: Notification[] = []

        props.notifications.map((notification: Notification) => {
            if (notification.markAsRead) {
                return notificationList.push(notification)
            } else {
                return unreadNotificationList.push(notification)
            }
        })

        sortByDate(unreadNotificationList)
        sortByDate(notificationList)

        setUnreadNotifications(unreadNotificationList)
        setNotifications(notificationList)

        setLoading(false)
    }

    useEffect(() => {
        handleNotifications()
    }, [props.notifications])

    if (loading) {
        return <PlaceHolder className={'h-32'} />
    }

    return (
        <>
            <div className={'w-full my-10 flex flex-col sm:flex-row sm:justify-between'}>
                <div className={'w-fit flex gap-x-1'}>
                    <Button
                        text={getTranslation('NOTIFICATION.VIEWS.LIST.BUTTON.READ')}
                        onClick={() => setShowNotifications(true)}
                        className={classNames(
                            'text-sm sm:text-base p-2 rounded-sm',
                            {'btn-blue' : showNotifications},
                            {'btn-gray' : !showNotifications}
                        )}
                    />
                    <Button
                        text={getTranslation('NOTIFICATION.VIEWS.LIST.BUTTON.UNREAD')}
                        onClick={() => setShowNotifications(false)}
                        className={classNames(
                            'text-sm sm:text-base p-2 rounded-sm',
                            {'btn-blue' : !showNotifications},
                            {'btn-gray' : showNotifications}
                        )}
                    />
                </div>

                <Button
                    text={
                        showNotifications ?
                            getTranslation('NOTIFICATION.VIEWS.LIST.BUTTON.MARK_AS_UNREAD') :
                            getTranslation('NOTIFICATION.VIEWS.LIST.BUTTON.MARK_AS_READ')
                    }
                    onClick={() => notificationsReadUpdater(showNotifications)}
                    className={'btn-blue text-sm sm:text-base sm:w-80 sm:px-2'}
                />
            </div>

            <NotificationTableView
                notifications={showNotifications ? notifications : unreadNotifications}
                notificationUpdater={notificationUpdater}
            />
        </>
    )
}

