import React from "react";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";


interface Props {
    customers: string[]
}

export function TotalCustomer(props: Props) {
    return (
        <Card
            iconName={'fa-solid fa-users'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_CUSTOMER.LABEL')}
            content={<>{props.customers.length}</>}
            iconColor={'text-slate-500 bg-slate-50'}
        />
    )
}