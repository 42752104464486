import {QuoteFragment} from "./fragments";
import {Customer, Link, Quote} from "../../models";
import {StandaloneQuote} from "../../models/standalone";
import {Cache} from "@boomrank/react-components";

export class QuoteFactory {
    static fromFragment = (fragment: QuoteFragment): Quote => {
        let quote = new Quote()
        quote.id = fragment.id
        quote.createdAt = new Date(fragment.created_at)
        quote.ref = fragment.ref
        quote.refDate = new Date(fragment.ref_date)
        quote.customerId = fragment.customer_id
        return quote
    }
}

export class StandaloneQuoteFactory {
    static fromQuote = (
        quote: Quote,
        cacheLink: Cache<Link>,
        cacheCustomers: Cache<Customer>,
    ): StandaloneQuote => {
        let links = cacheLink.values()

        let allLinks = links.filter((l) => l.quoteId === quote.id)
        let brokenLinks = allLinks.filter((l) => !l.isValid).length
        let customers = cacheCustomers.get(quote.customerId)

        let cost = allLinks.reduce((acc, l) => {
            if (l.priceHt ) {
                return acc + l.priceHt
            }
            return acc
        }, 0)

        let customerName = ''
        if (customers.length > 0) {
            customerName = customers[0].name
        }

        let standaloneQuote = {...quote} as StandaloneQuote
        standaloneQuote.cost = cost
        standaloneQuote.nbLinks = allLinks.length
        standaloneQuote.brokenLinks = brokenLinks
        standaloneQuote.customerName = customerName
        return standaloneQuote
    }
}