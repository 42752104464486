import {Api, FormErrorResponse, JwtToken} from "@boomrank/react-components";

import {endpoints} from "../../api";
import {Link as Backlink} from "../../../models";
import {LinkListResponse} from "../responses";


export class LinkBulkApi {
    static updateValidLinks(
        token: JwtToken,
        links: Backlink[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.update

        let backlinks = links.map((link) => {
            return {
                id: link.id,
                http_code_valid: true,
                last_http_code: 200,
                is_ok: true,
                is_valid: true,
                url_valid: true,
                content_valid: true,
                broken: false,
            }
        })

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(backlinks)
            }
        );
    }

    static updateIndexedLinks(
        token: JwtToken,
        links: Backlink[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.update

        let backlinks = links.map((link) => {
            return {
                id: link.id,
                is_indexed: true,
            }
        })

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(backlinks)
            }
        );
    }

    static updateBrokenLinks(
        token: JwtToken,
        links: Backlink[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.update

        let backlinks = links.map((link) => {
            return {
                id: link.id,
                broken: true,
                is_ok: false,
                is_valid: false,
            }
        })

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(backlinks)
            }
        );
    }

    static updateUnderCorrectionLinks(
        token: JwtToken,
        links: Backlink[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.update

        let backlinks = links.map((link) => {
            return {
                id: link.id,
                broken: false,
                under_correction: !link.underCorrection,
            }
        })

        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify(backlinks)
            }
        );
    }

    static launchAnalyze(
        token: JwtToken,
        linkIds: number[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.analyze

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    link_pks: linkIds,
                })
            }
        );
    }

    static checkAnalysis(
        token: JwtToken,
        baseUrl?: string
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.checkAnalysis;
        if (baseUrl) {
            url = baseUrl;
        }

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static isIndexed(
        token: JwtToken,
        linkIds: number[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.isIndexed

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    link_pks: linkIds,
                })
            }
        );
    }


    static delete(
        token: JwtToken,
        linkIds: number[]
    ): Promise<FormErrorResponse | LinkListResponse> {
        let url = endpoints.link.bulk.delete

        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    link_pks: linkIds,
                })
            }
        );
    }
}