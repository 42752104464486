import {LANG_FR} from '@boomrank/react-components'

const DEFAULT_ERROR = 'Une erreur est survenue.'
export const LANG = {
    ...LANG_FR,
    LANGUAGE: {
        1: {
            NAME: 'FR',
            LABEL: 'Français',
            FLAG: `🇫🇷`,
        },
        2: {
            NAME: 'IT',
            LABEL: 'Italien',
            FLAG: `🇮🇹`,
        },
        3: {
            NAME: 'ES',
            LABEL: 'Espagnol',
            FLAG: `🇪🇸`,
        },
        4: {
            NAME: 'DE',
            LABEL: 'Allemand',
            FLAG: `🇩🇪`,
        },
        5: {
            NAME: 'EN',
            LABEL: 'Anglais',
            FLAG: `🇬🇧`,
        },
        6: {
            NAME: 'NL',
            LABEL: 'Hollandais',
            FLAG: `🇳🇱`,
        },
        7: {
            NAME: 'BE',
            LABEL: 'Belge',
            FLAG: `🇧🇪`,
        },
        8: {
            NAME: 'PT',
            LABEL: 'Portugais',
            FLAG: `🇵🇹`,
        },
        9: {
            NAME: 'US',
            LABEL: 'Anglais US',
            FLAG: `🇺🇸`,
        },
    },
    INVOICE_STATUS: {
        1: {
            NAME: 'MONTHLY',
            LABEL: 'Mensuelle'
        },
        2: {
            NAME: 'DIRECT',
            LABEL: 'Direct'
        },
        3: {
            NAME: 'DIRECT_PAYED',
            LABEL: 'Direct (Payé par le client)'
        },
    },
    LAST_TYPE: {
        1: {
            NAME: 'TXT',
            LABEL: 'Texte',
        },
        2: {
            NAME: 'IMG',
            LABEL: 'Image',
        },
        UNKNOWN: {
            LABEL: '?',
        }
    },
    DUMMIES: {
        LINK: {
            CARD: {
                TOTAL_CUSTOMER: {
                    LABEL: 'Nombre de clients'
                },
                TOTAL_INDEXED: {
                    LABEL: 'Liens indexés'
                },
                TOTAL_LINK: {
                    LABEL: 'Nombre de liens'
                },
                TOTAL_PRICE: {
                    LABEL: 'Total Prix HT'
                },
                TOTAL_QUOTE: {
                    LABEL: 'Nombre de devis'
                },
                TOTAL_USER: {
                    LABEL: 'Nombre de consultants'
                },
                TOTAL_VALID: {
                    LABEL: 'Liens cassés'
                },
                TOTAL_BROKEN: {
                    LABEL: 'Liens cassés'
                },
            }
        }
    },
    FINANCE: {
        ROUTE: {
            HOME : {
                TITLE: 'Finance | Backlink Checker'
            }
        },
        FORM: {
            EXPORT: {
                BUTTON: 'Exporter en .csv'
            },
            FILTERING: {
                LABEL_YEAR: 'Années',
                LABEL_MONTH: 'Mois',
                LABEL_ACCOUNT: 'Consultants',
                LABEL_CUSTOMER: 'Clients',
                LABEL_PROVIDER: 'Plateformes',
            },
            TABLE: {
                CUSTOMER: 'Client',
                CREATED_AT: 'Date',
                ACCOUNT: 'Consultant',
                PROVIDER: 'Plateforme',
                PRICE_HT: 'Prix HT',
            }
        }
    },
    NETLINKING: {
        FORM: {
            LINK: {
                LABEL_URL: 'Url à analyser',
                LABEL_ANCHOR: 'Ancre à analyser',
                LABEL_COMMENT: 'Commentaire',
                LABEL_REGEX: 'Regex',
                URL_RESET: 'URL du GGSheet',
                URL_UPDATE: 'URL trouvé à la dernière analyse',
                LABEL_RESET: 'Ancre du GGSheet',
                LABEL_UPDATE: 'Ancre(s) trouvée(s) à la dernière analyse',
                REGEX_UPDATE: 'Mettre à jour la regex',
                EMPTY: 'Vide',
                REPLACE_BY: 'par',
                LABEL_SUBMIT: 'Enregistrer les changements',
                REQUESTED_URL_EMPTY: 'L\'URL ne peut pas être vide.',
                REQUESTED_URL_INVALID: 'L\'URL renseignée semble invalide.',
            },
            QUOTE: {
                LABEL_REF: 'Ref',
                LABEL_REF_DATE: 'Date',
                LABEL_SUBMIT: 'Modifier',
            }
        },
        ROUTE: {
            CUSTOMER: {
                LINK: {
                    EDIT:  {
                        TITLE: '{0} | {1} | Backlink Checker'
                    },
                },
                GET:  {
                    TITLE: '{0} | Backlink Checker'
                },
                LIST: {
                    TITLE: 'Clients | Backlink Checker'
                },
            },
            LINK: {
                EDIT: {
                    TITLE: '{0} | Backlink Checker'
                },
                LIST: {
                    TITLE: 'Liens | Backlink Checker'
                },
            },
            QUOTE: {
                LINK: {
                    EDIT: {
                        TITLE: '{0} | {1} | Backlink Checker'
                    },
                },
                EDIT: {
                    TITLE: '{0} | Backlink Checker'
                },
                GET: {
                    TITLE: '{0} | Backlink Checker'
                },
                LIST: {
                    TITLE: 'Devis | Backlink Checker'
                }
            },
            HOME: {
                TITLE: 'Netlinking | Backlink Checker'
            }
        },
        VIEW: {
            HOME: {
                CARD_HEADER: {
                    CUSTOMER: 'Clients renseignés',
                    LINK: 'Liens achetés',
                    QUOTE: 'Devis envoyés',
                }
            },
            ACCOUNT_HAS_CUSTOMER: {
                FOLLOW: 'Ajouter aux clients suivis',
                UNFOLLOW: 'Ne plus suivre ce client',
            },
            CUSTOMER: {
                TABLE: {
                    NAME: 'Client',
                    CREATED_AT: 'Création',
                    LINK: 'Nb liens',
                    BROKEN_LINK: 'Nb liens cassés',
                    ISSUES_LINK: 'Nb liens problématiques',
                    QUOTE: 'Nb devis',
                    TOTAL_BUDGET: 'Depenses',
                    ACTION: 'Actions',
                    EXPORT: 'Exporter',
                    INFO_MESSAGE: 'Appuyez sur Entrée pour filtrer',
                    ALL: 'Tout',
                },
                DELETE: {
                    SUBMIT_BUTTON: 'Supprimer',
                    CONFIRM_JS: 'Suppression définitive du client, ses liens et factures'
                }
            },
            QUOTE: {
                EDIT: {
                    SUCCESS: 'Enregistré',
                    ERROR: DEFAULT_ERROR
                },
                LIST: {
                    LABEL_EDIT: 'Mettre à jour'
                },
                TABLE: {
                    REF: 'Référence devis',
                    CUSTOMER_NAME: 'Client',
                    REF_DATE: 'Date',
                    NB_LINKS: 'Nb Liens',
                    BROKEN_LINKS: 'Nb Liens cassés',
                    COST: 'Coût HT',
                    EXPORT: 'Exporter',
                    INFO_MESSAGE: 'Appuyez sur Entrée pour filtrer',
                    ALL: 'Tout',
                },
                DELETE: {
                    SUBMIT_BUTTON: 'Supprimer',
                    CONFIRM_JS: 'Suppression définitive de la facture'
                }
            },
            LINK: {
                EDIT: {
                    SUCCESS: 'Enregistré',
                    ERROR: DEFAULT_ERROR,
                    SOON: 'Prochainement',
                    URL_NOT_FOUND: 'URL introuvable',
                    IS_INDEXED: 'Indexé',
                    IS_NOT_INDEXED: 'Non indexé',
                    IS_DISABLED: 'Fonctionnalité désactivée pour le moment',
                    LABEL_USER_EMAIL: 'Consultant',
                    LABEL_CUSTOMER_NAME: 'Client',
                    LABEL_LANGUAGE_NAME: 'Langue',
                    LABEL_QUOTE_REF: 'Devis',
                    LABEL_INVOICE_STATUS: 'Status du devis',
                    LABEL_PLATFORM_NAME: 'Plateforme',
                    LABEL_PRICE_HT: 'Prix',
                    LABEL_CREATED_AT: 'Créée',
                    LABEL_UPDATED_AT: 'Mis à jour',
                    LABEL_LAST_UPDATE: 'Dernière analyse',
                    LABEL_NEXT_ANALYZE: 'Prochaine analyse prévue',
                    MESSAGE_NEXT_ANALYZE: 'Une analyse est prévue dans les prochaines 24h.',
                    TITLE_SETTINGS: 'Configuration',
                    TITLE_LAST_ANALYZE: 'Analyse',
                    LABEL_TARGET_URL: 'URL',
                    LABEL_LAST_CONTENT: 'Ancre',
                    LAST_CONTENT_EMPTY: 'Vide',
                    LABEL_IS_INDEXED: 'Indexation',
                    LABEL_LAST_HTTP_CODE: 'HTTP Code',
                    LABEL_LAST_REL: 'Rel de l\'ancre',
                    LAST_REL_EMPTY: 'Vide',
                    LABEL_LAST_TYPE: 'Type d\'ancre',
                    LABEL_LAST_HEADERS: 'Headers',
                    LABEL_LAST_META: 'Métatags',
                },
                TABLE: {
                    KEY: 'Action',
                    CREATED_AT: 'Créée le',
                    PERIOD: 'Période',
                    LAST_UPDATE: 'Dernière analyse',
                    USER_EMAIL: 'Consultant',
                    IS_VALID: 'État',
                    IS_INDEXED: 'Indexation',
                    LAST_META: 'Métatags',
                    META_VALID: 'Validité des métatags',
                    LAST_CONTENT: 'Ancre trouvée',
                    CONTENT_VALID: 'Validité de l\'ancre',
                    LAST_REL: 'Rel',
                    REL_VALID: 'Validité du rel',
                    LAST_HEADERS: 'Headers',
                    HEADERS_VALID: 'Validité des headers',
                    NB_REDIRECT: 'Nb de redirection',
                    LAST_HTTP_CODE: 'Code réponse',
                    HTTP_CODE_VALID: 'Validité du code réponse',
                    URL: 'Source',
                    TARGET_URL: 'Lien attendu',
                    TARGET_CONTENT: 'Ancre',
                    LAST_TYPE: 'Type d\'ancre',
                    TARGET_EXPRESSION: 'Expression ciblée',
                    TARGET_REGEX: 'Regex de l\'ancre',
                    PROVIDER_NAME: 'Plateforme',
                    LANGUAGE_NAME: 'Langue',
                    CUSTOMER_NAME: 'Client',
                    QUOTE_REF: 'Devis',
                    INVOICE_STATUS: 'Status du devis',
                    PRICE_HT: 'Prix HT',
                    EXPORT: 'Exporter',
                    INFO_MESSAGE: 'Appuyez sur Entrée pour filtrer',
                    ALL: 'Tout',
                    'QUOTE_N/A': 'N/A',
                },
                DELETE: {
                    SUBMIT_BUTTON: 'Supprimer',
                    CONFIRM_JS: 'Suppression définitive du lien, à effectuer uniquement si et seulement si erreur dans l’importation du lien"'
                },
                MARK_AS_BROKEN: {
                    SUBMIT_BUTTON: 'Lien cassé',
                    UNSUBMIT_BUTTON: 'Lien non cassé',
                    CONFIRM_JS: 'Définir comme définitivement cassé ? Le lien sera archivé et ne sera plus analysé chaque mois'
                },
                LAUNCH_ANALYZE: {
                    SUBMIT_BUTTON: 'Lancer une analyse',
                    CONFIRM_JS: 'Voulez-vous relancer une analyse pour le(s) lien(s) sélectionné(s) ?'
                },
            }
        }
    },
    NOTIFICATION : {
        VIEWS: {
            UNREAD_LIST: {
                TITLE: 'Notifications récentes',
                BUTTON_TITLE: 'Marquer comme lue la sélection',
                NOTIFICATION_CONTENT: {
                    NX101: `Code erreur NX101`,
                    NX102: `Code erreur NX102`,
                    NX103: `Code erreur NX103`,
                    NX104: `Code erreur NX104`,
                }
            },
            LIST: {
                BUTTON: {
                    READ: 'Lues',
                    UNREAD: 'Non Lues',
                    MARK_AS_READ: 'Tout marquer comme lue',
                    MARK_AS_UNREAD: 'Tout marquer comme non lue',
                }
            },
            EDIT: {
                ENABLE: 'Activer les notifications',
                DISABLE: 'Désactiver les notifications',
            },
            TABLE: {
                FILTER: {
                    DEFAULT: 'Filtrer par client',
                    TITLE: 'Sélectionner un client',
                },
                CODE: {
                    NX101: `Code erreur NX101`,
                    NX102: `Code erreur NX102`,
                    NX103: `Code erreur NX103`,
                    NX104: `Code erreur NX104`,
                },
            }
        },
    },
    BREADCRUMB: {
        HOMEPAGE: 'Accueil',
        CUSTOMERS: 'Clients',
        QUOTES: 'Devis',
        LINKS: 'Liens',
        QUOTE_EDIT: 'Modifier'
    },
    TEMPLATE: {
        HELLO: 'Bonjour <b>{0}</b>,',
        PROFILE: 'Profile',
        FINANCE: 'Finance',
        LOGOUT: 'Déconnexion',
        NEED_HELP: 'Besoin d\'aide ?',
    },
    FULL_TABLE: {
        SEARCH: 'Rechercher',
        SELECT_ALL: 'Tout',
    },
    QUERY_FIELD: {
        PRESS_ENTER: 'Appuyez sur Entrée pour filtrer'
    },
}