import React from "react";
import {StandaloneLink} from "../../../../models/standalone";
import {Card} from "../../card";
import {getTranslation} from "../../../../intl";

interface Props {
    links: StandaloneLink[]
}

export function TotalBrokenLink(props: Props) {
    let total = props.links.filter((l) => l.broken).length

    return (
        <Card
            iconName={'fa-solid fa-link-slash'}
            title={getTranslation('DUMMIES.LINK.CARD.TOTAL_BROKEN.LABEL')}
            content={<>{props.links.filter((l) => l.broken).length}</>}
            iconColor={total > 0 ? 'text-red-500 bg-red-50' : 'text-slate-500 bg-slate-50'}
        />
    )
}