import React from "react";

import {Account, Customer, Provider, Quote} from "../../../../models";
import {StandaloneLink} from "../../../../models/standalone";

import {Cache} from "@boomrank/react-components";
import {Paths} from "@boomrank/react-components/src/dummies/table/v2/models";

import {TotalPrice} from "./total-price";
import {TotalUser} from "./total-user";
import {TotalIndexed} from "./total-indexed";
import {TotalValid} from "./total-valid";
import {TotalLink} from "./total-link";
import {TotalCustomer} from "./total-customer";
import {TotalQuote} from "./total-quote";
import {BestProvider} from "./best-provider";
import {TotalBrokenLink} from "./total-broken-link";


interface Props {
    columns: (Paths<StandaloneLink>)[]
    links: StandaloneLink[]
    cacheAccounts: Cache<Account>
    cacheCustomers: Cache<Customer>
    cacheProviders: Cache<Provider>
    cacheQuotes: Cache<Quote>
}

export function MainLinkCard(props: Props) {
    let children: React.ReactElement[] = []

    if (props.columns.indexOf('userEmail') !== -1) {
        if (children.length < 5) {
            let accounts = props.links.reduce((accounts: {[e: number]: Account}, link) => {
                if (Object.keys(accounts).indexOf(link.accountId.toString()) === -1) {
                    accounts[link.accountId] = props.cacheAccounts.get(link.accountId)[0]
                }
                return accounts
            }, [])

            if (Object.keys(accounts).length > 1) {
                children.push(
                    <TotalUser accounts={Object.values(accounts)} />
                )
            }
        }
    }

    if (props.columns.indexOf('isIndexed') !== -1) {
        let indexedLinks = props.links.filter((link) => link.isIndexed)
        if (
            children.length < 5 && indexedLinks.length > 0) {
            children.push(
                <TotalIndexed links={props.links} indexedLinks={indexedLinks} />
            )
        }
    }

    if (props.columns.indexOf('providerName') !== -1) {
        if (children.length < 5) {
            children.push(
                <BestProvider links={props.links} />
            )
        }
    }

    if (children.length < 5) {
        children.push(
            <TotalLink links={props.links} />
        )
    }

    if (props.columns.indexOf('isValid') !== -1) {
        let validLinks = props.links.filter((link) => link.isValid)
        if (
            children.length < 5 && validLinks.length > 0) {
            children.push(
                <TotalValid links={props.links} validLinks={validLinks} />
            )
        }
    }

    if (props.columns.indexOf('customerName') !== -1 || props.columns.indexOf('customerId') !== -1) {
        let uniqueCustomers = props.links.reduce((customers: string[], link) => {
            if (customers.indexOf(link.customerName) === -1) {
                customers.push(link.customerName)
            }
            return customers
        }, [])
        if (
            children.length < 5 && uniqueCustomers.length > 1) {
            children.push(
                <TotalCustomer customers={uniqueCustomers} />
            )
        }
    }

    if (props.columns.indexOf('quoteRef') !== -1) {
        if (children.length < 5) {
            let quotes = props.links.reduce((quotes: {[e: number]: Quote}, link) => {
                if (link.quoteId) {
                    if (Object.keys(quotes).indexOf(link.quoteId.toString()) === -1) {
                        quotes[link.quoteId] = props.cacheQuotes.get(link.quoteId!.toString())[0]
                    }
                }
                return quotes
            }, [])

            if (Object.keys(quotes).length > 1) {
                children.push(
                    <TotalQuote quotes={Object.values(quotes)} />
                )
            }
        }
    }


    return (
        <div className={
            'w-full h-fit mb-20 grid grid-cols-2 md:grid-cols-3 ' +
            'lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3'
        }>
            <TotalPrice links={props.links} />

            {
                children.map((element: React.ReactElement, idx) => {
                    return (
                        <div key={idx}>
                            {element}
                        </div>
                    )
                })
            }

            <TotalBrokenLink links={props.links} />
        </div>
    )
}